import { Button, Col, Form, notification, Radio, Row, Space } from 'antd'
import { RuleObject, RuleRender } from 'antd/lib/form'
import { NamePath } from 'antd/lib/form/interface'
import Select from 'antd/lib/select'
import dayjs from 'dayjs'
import { styled } from 'goober'
import { filter } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'

import { apiDateFormat } from '_backend/constants/date-format'
import { requiredCustomMessage, REQUIRED_RULES } from '_backend/constants/form-rules'
import { AppModal } from 'components/AppModal'
import DatePicker from 'components/DatePicker'
import { TextArea } from 'components/Input'
import {
  useGetMasterSlotQRY,
  useGetServicePropDayOffQRY,
  useSaveServicePropDayOffListMTT,
  useSaveServicePropDayOffMTT,
} from 'services/property-management/property-management-service'

import { IDuplicateServicePropDayOffValue, SetDayOffDuplicateForm } from './SetDayOffDuplicateForm'

const ShowComponent = styled('div')<{ show?: 'true' }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
`

enum ModalSetDayOffModeEnum {
  ADD = 'เพิ่มวันหยุด',
  DUPLICATE = 'Duplicate',
}

interface ModalSetDayOffFormValues {
  formMode: ModalSetDayOffModeEnum

  // add mode
  dayOff: string
  servicePropId: number
  slotIDStart: number
  slotIDEnd: number
  remark?: string
  id?: number

  // duplicate mode
  duplicateDayOffList: IDuplicateServicePropDayOffValue[]
}

interface IModalServiceTypeProps {
  visible: boolean
  onDismiss: () => void

  id?: number
  servicePropId: number
}

export const ModalSetOffDay = (props: IModalServiceTypeProps) => {
  const { visible, onDismiss, id = 0, servicePropId } = props

  const [form] = Form.useForm<ModalSetDayOffFormValues>()
  const formMode = Form.useWatch('formMode', form)

  const { mutateAsync: mutateSaveServicePropDayOff, isLoading: isSaveServicePropDayOffLoading } =
    useSaveServicePropDayOffMTT()
  const { mutateAsync: mutateSaveServicePropDayOffList, isLoading: isSaveServicePropDayOffListLoading } =
    useSaveServicePropDayOffListMTT()
  const { data: servicePropDayOff } = useGetServicePropDayOffQRY({ id })
  const { data: masterSlotList } = useGetMasterSlotQRY()

  const optionsStart = useMemo(() => {
    return masterSlotList?.map((masterSlot) => ({
      label: `${masterSlot.start_t}`,
      value: masterSlot.id,
    }))
  }, [masterSlotList])

  const optionsEnd = useMemo(() => {
    return masterSlotList?.map((masterSlot) => ({
      label: `${masterSlot.end_t}`,
      value: masterSlot.id,
    }))
  }, [masterSlotList])

  const isCreate = useMemo(() => id === 0, [id])

  const validateRequireRelateMoreThanCheckbox = useCallback(
    (relateFieldName: NamePath, errorMessage: string): RuleRender =>
      ({ getFieldValue }) => {
        const validator = (_: RuleObject, value: string) => {
          const relateFieldValue = getFieldValue(relateFieldName)

          let isValid

          if (relateFieldValue <= value) {
            isValid = true
          }

          if (isValid) {
            return Promise.resolve()
          }
          return Promise.reject(new Error(errorMessage))
        }

        return {
          validator,
        }
      },
    [],
  )
  const submitDuplicateDayOff = useCallback(
    async (values: ModalSetDayOffFormValues) => {
      const { duplicateDayOffList } = values

      const duplicateDayOffListOnlyChecked = filter(duplicateDayOffList, (e) => !!e.isCheck)
      await mutateSaveServicePropDayOffList(
        duplicateDayOffListOnlyChecked.map((e) => ({
          dayOff: dayjs(e.dayOff).format(apiDateFormat),
          id: 0,
          servicePropId,
          remark: e.remark,
          slotIDStart: e.slotIDStart,
          slotIDEnd: e.slotIDEnd,
          slotIDStartLabel: e.starttime,
          slotIDEndLabel: e.endtime,
        })),
        {
          onSuccess: () => {
            notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลสำเร็จ', duration: 2 })
          },
        },
      )
    },
    [mutateSaveServicePropDayOffList, servicePropId],
  )

  const onSubmit = useCallback(
    async (values: ModalSetDayOffFormValues) => {
      switch (formMode) {
        case ModalSetDayOffModeEnum.ADD:
          await mutateSaveServicePropDayOff(
            {
              ...values,
              dayOff: dayjs(values.dayOff).format(apiDateFormat),
              id,
              servicePropId,
            },
            {
              onSuccess: () => {
                notification.success({ message: 'สำเร็จ', description: 'เพิ่มข้อมูลสำเร็จ', duration: 2 })
              },
            },
          )
          break
        case ModalSetDayOffModeEnum.DUPLICATE:
          await submitDuplicateDayOff(values)
          break
        default:
          break
      }

      if (isCreate) form.resetFields()
      onDismiss()
    },
    [form, formMode, id, isCreate, mutateSaveServicePropDayOff, onDismiss, servicePropId, submitDuplicateDayOff],
  )

  const onCloseClick = useCallback(() => {
    form.resetFields()
    onDismiss()
  }, [form, onDismiss])

  const handleChangeSlotTime = useCallback((value: number) => {
    console.log(`selected ${value}`)
  }, [])

  useEffect(() => {
    if (isCreate) form.resetFields()
  }, [form, isCreate])

  useEffect(() => {
    if (!visible) {
      form.resetFields()
    }
  }, [form, visible])

  useEffect(() => {
    form.setFieldsValue({
      dayOff: servicePropDayOff?.dayOff,
      remark: servicePropDayOff?.remark,
      slotIDStart: servicePropDayOff?.slotIDStart,
      slotIDEnd: servicePropDayOff?.slotIDEnd,
    })
  }, [form, id, servicePropDayOff, visible])

  const getValueByMode = useCallback(
    <T extends any>(targetMode: ModalSetDayOffModeEnum, value: T) => {
      if (formMode === targetMode) return value
    },
    [formMode],
  )

  const title = useMemo(() => {
    if (isCreate) return 'เพิ่มวันหยุด'
    return 'แก้ไขวันหยุด'
  }, [isCreate])

  const duplicateButton = useMemo(() => {
    if (isCreate)
      return <Radio.Button value={ModalSetDayOffModeEnum.DUPLICATE}>{ModalSetDayOffModeEnum.DUPLICATE}</Radio.Button>
    else {
      return <></>
    }
  }, [isCreate])

  return (
    <AppModal visible={visible} width={600} destroyOnClose>
      <AppModal.Header onCloseClick={onCloseClick}>{title}</AppModal.Header>
      <Form
        form={form}
        onFinish={onSubmit}
        initialValues={{
          formMode: ModalSetDayOffModeEnum.ADD,
        }}
        layout="vertical"
        autoComplete="off"
      >
        <AppModal.Body>
          <Form.Item name="formMode">
            <Radio.Group buttonStyle="solid">
              {isCreate && <Radio.Button value={ModalSetDayOffModeEnum.ADD}>{ModalSetDayOffModeEnum.ADD}</Radio.Button>}
              {duplicateButton}
            </Radio.Group>
          </Form.Item>
          <ShowComponent show={getValueByMode(ModalSetDayOffModeEnum.ADD, 'true')}>
            <Form.Item
              label="วันที่ปิดทำการ"
              required
              name="dayOff"
              rules={getValueByMode(ModalSetDayOffModeEnum.ADD, requiredCustomMessage('กรุณาเลือกประเภทเวลา'))}
            >
              <DatePicker disabledDate={(current) => current.isBefore(dayjs().subtract(1, 'day'))} />
            </Form.Item>
            <Row gutter={[24, 24]}>
              <Col span={11}>
                <Form.Item
                  name="slotIDStart"
                  validateTrigger={['onChange', 'onBlur']}
                  label="ช่วงเวลาปิดทำการ"
                  required={formMode === ModalSetDayOffModeEnum.ADD}
                >
                  <Select
                    style={{ width: '100%', height: 38 }}
                    onChange={handleChangeSlotTime}
                    options={optionsStart}
                  />
                </Form.Item>
              </Col>
              <Col style={{ alignSelf: 'center' }}>-</Col>
              <Col span={11}>
                <Form.Item
                  name="slotIDEnd"
                  required={formMode === ModalSetDayOffModeEnum.ADD}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={getValueByMode(ModalSetDayOffModeEnum.ADD, [
                    validateRequireRelateMoreThanCheckbox(['slotIDStart'], 'กรุณาเลือกเวลาที่มากกว่า'),
                  ])}
                  style={{ marginTop: 30 }}
                >
                  <Select style={{ width: '100%', height: 38 }} onChange={handleChangeSlotTime} options={optionsEnd} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Form.Item
                  name="remark"
                  label="หมายเหตุ"
                  style={{ paddingTop: 24 }}
                  required={formMode === ModalSetDayOffModeEnum.ADD}
                  rules={getValueByMode(ModalSetDayOffModeEnum.ADD, REQUIRED_RULES)}
                >
                  <TextArea rows={4} autoSize={{ minRows: 4, maxRows: 5 }} />
                </Form.Item>
              </Col>
            </Row>
          </ShowComponent>
          <ShowComponent show={getValueByMode(ModalSetDayOffModeEnum.DUPLICATE, 'true')}>
            <SetDayOffDuplicateForm isValidate={formMode === ModalSetDayOffModeEnum.DUPLICATE} />
          </ShowComponent>
        </AppModal.Body>
        <AppModal.Footer>
          <Form.Item shouldUpdate>
            <Space size={24} direction="horizontal">
              <Button onClick={onDismiss}>ยกเลิก</Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={isSaveServicePropDayOffLoading || isSaveServicePropDayOffListLoading}
                disabled={isSaveServicePropDayOffLoading || isSaveServicePropDayOffListLoading}
              >
                บันทึก
              </Button>
            </Space>
          </Form.Item>
        </AppModal.Footer>
      </Form>
    </AppModal>
  )
}
