export enum EnumKioskRegisterIsPermanentBan {
  TEMPORARY = 0,
  PERMANENT = 1,
}
export enum EnumKioskRegisterBanFlag {
  AVAILABLE = 0,
  BANNED = 1,
  CANCEL_BAN = 2,
}

export enum EnumKioskRegisterApproveStatus {
  WAITING = 0,
  APPROVED = 1,
  BANNED = 2,
}

export enum EnumKioskRegisterApproveId {
  APPROVED = 1,
  REJECT = 0,
}

export interface IGetKioskRegisterResponse {
  id: number
  tbServiceId: number
  firstdate: string
  firststf: number
  modifydate: string
  modifydatenm: string
  modifystf: number
  canceldate: null | string
  cancelstf: null | number

  firstname: string | null
  lastname: string | null
  cid: string
  address: string | null
  addrDisplay: string | null
  tel: string
  email: string
  mdKioskProductId: number
  mdKioskSubProductId: number
  productName: string
  subProductName: string
  isPttRelate: number | null
  pttRelateNote: string | null
  status: EnumKioskRegisterApproveStatus | null
  statusDisplay: string
  userid: number
  userDisplay: string
  cardFileName: string | null
  cardPath: string | null
  productFileName: string | null
  productPath: string | null
  vendorFileName: string | null
  vendorPath: string | null
  mdKioskApproveStatusId: EnumKioskRegisterApproveStatus
  approvStf: number | null
  approveDate: string | null
  approveNote: string | null
  adminNote: string | null
  tambonID: number | null
  districtID: number | null
  provinceID: number | null
  isPermanentBan: EnumKioskRegisterIsPermanentBan | null
  banStartDate: string | null
  banEndDate: string | null
  banStartDateDisplay: string | null
  banEndDateDisplay: string | null
  banRemark: string | null
  banFlg: EnumKioskRegisterBanFlag | null
}
