import { Badge } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { styled } from 'goober'
import { chain, compact } from 'lodash'
import { useState, useMemo } from 'react'
import { Event } from 'react-big-calendar'

import { AppBigCalendar } from 'components/AppBigCalendar'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { useGetOrderCalendarDisplayQRY } from 'services/order-response/order-response-service-service'
import theme from 'theme/goober'

import type { BadgeProps } from 'antd'

const timeFormat = (time: string) => {
  const times = time.split(':')
  return `${times[0]} : ${times[1]}`
}

const mapBadgeDotColor = (status: BadgeProps['status']) =>
  status === 'success' ? theme.color.lightGreenPrimary : theme.color.softGray
const mapBadgeTextColor = (status: BadgeProps['status']) =>
  status === 'success' ? theme.color.lightGreenBackground : theme.color.grayCard

const Container = styled.div``

const ListBadge = styled<BadgeProps>(Badge)`
  .ant-badge-status-dot {
    margin-right: -4px;
    background-color: ${(props) => mapBadgeDotColor(props.status)};
  }
  .ant-badge-status-text {
    font-size: 14px;
    overflow-wrap: unset;
    padding: 2px 4px;
    border-radius: 4px;
    background-color: ${(props) => mapBadgeTextColor(props.status)};
  }
`

interface IOrderCalendarEvent extends Event {
  id: number
  badge?: BadgeProps['status']
}

interface IOrderCalendarProps {
  serviceId: number
  selectedValue: Dayjs | null
  onSelected: (date: Dayjs | null) => void
}

export const OrderCalendar = (props: IOrderCalendarProps) => {
  const [filter, setFilter] = useState<Dayjs>(dayjs())

  const { data: service } = useGetServiceQRY(props.serviceId)
  const { mdServiceCatId = 0 } = service ?? {}
  const isKiosk = +mdServiceCatId === 3

  const { data: orders } = useGetOrderCalendarDisplayQRY({
    month: (filter?.month() || 0) + 1,
    year: filter?.year(),
    serviceId: props.serviceId,
  })

  const events = useMemo<IOrderCalendarEvent[]>(() => {
    const results = chain(orders ?? [])
      .uniqBy((e) => e.tborderId)
      .transform((acc: IOrderCalendarEvent[], order) => {
        const { tborderId, serviceProperties, userDisplay, bookingDate, startTime, endTime } = order

        const bookingDateOnly = bookingDate.split('T')[0]
        const bookingStart = dayjs(compact([bookingDateOnly, startTime || '00:00']).join('T'), {
          locale: 'Asia/Bangkok',
        }).toDate()
        const bookingEnd = dayjs(compact([bookingDateOnly, endTime || '23:59']).join('T'), {
          locale: 'Asia/Bangkok',
        }).toDate()

        const titleList: string[] = []
        if (isKiosk) {
          titleList.push(serviceProperties, userDisplay)
        } else {
          titleList.push(compact([startTime, endTime]).join(' - '), userDisplay)
        }

        acc.push({
          id: tborderId,
          title: compact(titleList).join(' '),
          start: bookingStart,
          end: bookingEnd,
          allDay: false,
        })
        return acc
      }, [])
      .uniqBy((e) => e.id)
      .concat([
        {
          id: -1,
          title: 'Today',
          start: dayjs().hour(6).startOf('hour').toDate(),
          end: dayjs().hour(6).startOf('hour').toDate(),
        },
      ])
      .value()
    return results
  }, [isKiosk, orders])

  return (
    <Container>
      <AppBigCalendar<IOrderCalendarEvent>
        events={events}
        onNavigate={(newDate, view, action) => {
          setFilter(dayjs(newDate))
          props.onSelected(null)
        }}
        onSelectEvent={(d) => {
          props.onSelected(dayjs(d.start))
        }}
        style={{
          height: '600px',
        }}
        showMultiDayTimes
        doShowMoreDrillDown
        popup
      />
    </Container>
  )
}
