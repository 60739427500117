/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, DatePicker, Form, notification, Row, Space } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useCallback, useEffect, useMemo } from 'react'

import { componentDisplayDateFormat } from '_backend/constants/date-format'
import { REQUIRED_RULES } from '_backend/constants/form-rules'
import { AppModal } from 'components/AppModal'
import { TextArea } from 'components/Input'
import { ISubmitKioskDayOffParams } from 'services/kiosk-day-off/kiosk-day-off-params'
import { useGetKioskDayOffMTT, useSaveKioskDayOffMTT } from 'services/kiosk-day-off/kiosk-day-off-service'

interface IModalKioskDayOffFormValues extends Omit<ISubmitKioskDayOffParams, 'tbServiceId'> {}

type IDateRange = [Dayjs | undefined, Dayjs | undefined]

interface IInternalModalKioskDayOffFormValues extends Omit<IModalKioskDayOffFormValues, 'startDate' | 'endDate'> {
  dateRange: IDateRange
}

interface IModalKioskDayOffProps {
  serviceId: number
  kioskDayOffId?: number
  visible: boolean
  onDismiss: () => void
  onConfirm: () => void
}
export const ModalKioskDayOff = (props: IModalKioskDayOffProps) => {
  const { kioskDayOffId, serviceId, visible, onDismiss, onConfirm } = props

  const [form] = Form.useForm<IInternalModalKioskDayOffFormValues>()
  const { data: preInitialValues } = useGetKioskDayOffMTT(kioskDayOffId)
  const { mutate: saveKioskDayOff } = useSaveKioskDayOffMTT()

  const isEditMode = useMemo(() => {
    if (preInitialValues?.id) return true
    if (preInitialValues?.id === 0) return true
    return false
  }, [preInitialValues?.id])

  const initialValues = useMemo((): Partial<IInternalModalKioskDayOffFormValues> => {
    const { id = 0, startDate, endDate, remark } = preInitialValues ?? {}
    let dateRange: IDateRange | undefined = undefined
    if (startDate && endDate) {
      dateRange = [dayjs(startDate), dayjs(endDate)]
    }

    return {
      id,
      dateRange,
      remark,
    }
  }, [preInitialValues])

  const handleDismiss = useCallback(() => {
    form.resetFields()
    onDismiss()
  }, [form, onDismiss])

  const onSubmit = useCallback(
    (values: IInternalModalKioskDayOffFormValues) => {
      const [startDate, endDate] = values.dateRange
      saveKioskDayOff(
        {
          ...values,
          id: preInitialValues?.id ?? 0,
          startDate: dayjs(startDate).format(componentDisplayDateFormat),
          endDate: dayjs(endDate).format(componentDisplayDateFormat),
          tbServiceId: serviceId,
        },
        {
          onSuccess() {
            notification.success({ message: 'สำเร็จ', description: 'บันทึกวันหยุดสำเร็จ', duration: 2 })
            onConfirm()
          },
        },
      )
      form.resetFields()
    },
    [form, onConfirm, preInitialValues],
  )

  const modalHeaderLabel = useMemo(() => {
    if (isEditMode) return 'แก้ไขวันหยุด'
    return 'เพิ่มวันหยุด'
  }, [isEditMode])

  const submitButtonLabel = useMemo(() => {
    return 'บันทึก'
  }, [])

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <AppModal visible={visible} destroyOnClose forceRender>
      <Form form={form} onFinish={onSubmit} layout="vertical" autoComplete="off">
        <AppModal.Header onCloseClick={handleDismiss}>{modalHeaderLabel}</AppModal.Header>
        <AppModal.Body>
          <Form.Item
            label="วันที่เริ่มต้นปิดทำการ - วันที่สิ้นสุดปิดทำการ"
            name="dateRange"
            rules={REQUIRED_RULES}
            required
          >
            <DatePicker.RangePicker />
          </Form.Item>
          <Form.Item name="remark" label="หมายเหตุ" style={{ paddingTop: 24 }} rules={REQUIRED_RULES} required>
            <TextArea rows={4} autoSize={{ minRows: 4, maxRows: 5 }} />
          </Form.Item>
        </AppModal.Body>
        <Row className="custom-modal-footer" wrap={false} justify="space-between" style={{ width: '100%' }}>
          <Col>
            <Button onClick={handleDismiss}>ยกเลิก</Button>
          </Col>
          <Col>
            <Space size={18} direction="horizontal">
              <Button type="primary" htmlType="submit">
                {submitButtonLabel}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </AppModal>
  )
}
