import { Button, Card, Col, DatePicker, Form, Row, Select } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import Table, { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import { chain, set } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { apiDateFormat, componentDisplayDateFormat } from '_backend/constants/date-format'
import { REQUIRED_RULES } from '_backend/constants/form-rules'
import RangePicker from 'components/RangePicker'
import { Txt } from 'components/Txt'
import { getColumnNumberByPage } from 'helpers/column-helper'
import { truncateString } from 'helpers/string-helper'
import { GetServiceTypeEnum, ServiceActiveStatusEnum } from 'services/enco-service/enco-service-param'
import { IServiceData } from 'services/enco-service/enco-service-response'
import { useGetServicesQRY } from 'services/enco-service/enco-service-service'
import { IGetReportParams } from 'services/report/report-params'
import { useExportReportMTT, useGetReportQRY } from 'services/report/report-service'

import { getFilterValue, getReportConfig, IPageReportQueryParams } from './ReportFilterModal'

const isNumberBlank = (value?: string | null) => {
  if (!value) return undefined
  return Number(value)
}
const isDateBlank = (value?: string | null) => {
  if (!value) return undefined
  return dayjs(value)
}

export const PageReport = () => {
  const { reportId: reportIdParam } = useParams()

  const [searchParams, setSearchParams] = useSearchParams()
  const reportId = Number(reportIdParam)

  const serviceId = useMemo(() => isNumberBlank(searchParams.get('serviceId')), [searchParams])
  const startDate = useMemo(() => isDateBlank(searchParams.get('startDate')), [searchParams])
  const endDate = useMemo(() => isDateBlank(searchParams.get('endDate')), [searchParams])
  const year = useMemo(() => searchParams.get('year'), [searchParams])
  const month = useMemo(() => searchParams.get('month'), [searchParams])
  const invoiceTypeId = useMemo(() => searchParams.get('invoiceTypeId'), [searchParams])
  const packageTypeId = useMemo(() => searchParams.get('packageTypeId'), [searchParams])

  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)

  const filter = useMemo((): IGetReportParams => {
    let newFilter: IGetReportParams = { reportId }
    if (serviceId) newFilter.serviceId = serviceId
    if (startDate) newFilter.startDate = dayjs(startDate).format(apiDateFormat)
    if (endDate) newFilter.endDate = dayjs(endDate).format(apiDateFormat)
    if (year) newFilter.year = +year
    if (month) newFilter.month = +month
    if (invoiceTypeId) newFilter.invoiceTypeId = +invoiceTypeId
    if (packageTypeId) newFilter.packageTypeId = +packageTypeId
    return newFilter
  }, [reportId, serviceId, startDate, endDate, year, month, invoiceTypeId, packageTypeId])

  const { data: report } = useGetReportQRY(filter)
  const { data: service } = useGetServicesQRY({ serviceType: GetServiceTypeEnum.ALL })
  const { mutateAsync: downloadExcel } = useExportReportMTT()

  const handleClickDownload = useCallback(() => {
    downloadExcel(filter)
  }, [downloadExcel, filter])

  const handleFilterChange = (values: IPageReportQueryParams) => {
    const params: Partial<Record<keyof IPageReportQueryParams, string>> = {}
    Object.entries(values).forEach(([k, v]) => {
      if (v) {
        set(params, k, v)
      }
    })
    setSearchParams(params)
  }

  const reportObject = useMemo(() => {
    if (report) return JSON.parse(report.reportData)
    return []
  }, [report])

  const columns = useMemo((): ColumnsType<any> => {
    const columnKeys: ColumnsType<any> = []
    if (reportObject[0]) {
      Object.keys(reportObject[0]).forEach((key) => {
        columnKeys.push({
          title: key,
          dataIndex: key,
          align: 'center',
          render: (value: any) => (typeof value === 'object' ? '' : <span>{truncateString(value, 20)}</span>),
        })
      })
    }
    return [
      {
        title: 'ลำดับ',
        align: 'center',
        render: (_: any, record: any, index: number) => (
          <Txt ag="body20">{getColumnNumberByPage(currentPage, index, currentPageSize)}</Txt>
        ),
      },
      ...columnKeys,
    ]
  }, [reportObject, currentPage, currentPageSize])

  const handlePageChange = useCallback((page: number, pageSize: number) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }, [])

  if (!service) return null
  return (
    <>
      <ContentHeader title="Report" subTitle={report?.reportName} />
      <ContentBody>
        <FilterExportSection
          reportId={reportId}
          onDownload={handleClickDownload}
          services={service}
          initialValues={filter}
          onFilterChange={handleFilterChange}
        />

        <Table
          scroll={{ x: '100%' }}
          rowKey="id"
          dataSource={reportObject}
          columns={columns}
          pagination={{
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            onChange: handlePageChange,
            current: currentPage,
          }}
        />
      </ContentBody>
    </>
  )
}

interface IReportFilterFormValues {
  serviceId?: number
  rangeDate?: [dayjs.Dayjs, dayjs.Dayjs]
  startDate?: dayjs.Dayjs
  monthYear?: dayjs.Dayjs
  invoiceTypeId?: number
  packageTypeId?: number
}

interface IFilterExportSectionProps {
  reportId: number
  services: IServiceData[]
  initialValues?: IPageReportQueryParams
  onFilterChange: (values: IPageReportQueryParams) => void
  onDownload: () => void
}

const FilterExportSection = (props: IFilterExportSectionProps) => {
  const { reportId, initialValues, onFilterChange, services, onDownload } = props
  const [form] = Form.useForm<IReportFilterFormValues>()

  const filterInitialValues = useMemo(() => {
    let filter: IReportFilterFormValues = {}
    if (initialValues?.serviceId) filter.serviceId = initialValues?.serviceId
    if (initialValues?.year && initialValues?.month)
      filter.monthYear = dayjs()
        .year(initialValues?.year)
        .month(initialValues?.month - 1)
        .startOf('day')
    if (initialValues?.invoiceTypeId) filter.invoiceTypeId = initialValues?.invoiceTypeId
    if (initialValues?.packageTypeId) filter.packageTypeId = initialValues?.packageTypeId
    if (initialValues?.startDate && initialValues?.endDate)
      filter.rangeDate = [dayjs(initialValues?.startDate), dayjs(initialValues?.endDate)]

    if (initialValues?.startDate) filter.startDate = dayjs(initialValues?.startDate)

    return filter
  }, [initialValues])

  const filterConfig = useMemo(() => {
    return getReportConfig(reportId)
  }, [reportId])

  const handleFinishForm = useCallback(() => {
    onDownload()
  }, [onDownload])

  const handleFormChange = useCallback(() => {
    const values = form.getFieldsValue()
    const reportParams: IPageReportQueryParams = {}

    if (values.serviceId) {
      reportParams.serviceId = values.serviceId
    }

    if (values.rangeDate) {
      reportParams.startDate = getFilterValue(0, values.rangeDate)
      reportParams.endDate = getFilterValue(1, values.rangeDate)
    }

    if (values.startDate) {
      reportParams.startDate = dayjs(values.startDate).format(componentDisplayDateFormat)
    }

    if (values.monthYear) {
      const e = dayjs(values.monthYear)
      reportParams.year = e.year()
      reportParams.month = e.month() + 1 // +1 because dayjs month start at 0
    }

    if (values.invoiceTypeId) {
      reportParams.invoiceTypeId = values.invoiceTypeId
    }

    if (values.packageTypeId) {
      reportParams.packageTypeId = values.packageTypeId
    }

    onFilterChange(reportParams)
  }, [form, onFilterChange])

  const serviceOptions = useMemo((): DefaultOptionType[] => {
    return chain(services ?? [])
      .filter((e) => {
        const isKiosk = +e.mdServiceCatId === 3
        const isActive = e.active === ServiceActiveStatusEnum.ACTIVE

        if (filterConfig.filterServiceKioskOnly) {
          return isKiosk && isActive
        }
        return !isKiosk && isActive
      })
      .orderBy([(e) => e.recno], ['asc'])
      .map((e): DefaultOptionType => {
        return {
          label: e.name,
          value: e.id,
        }
      })
      .value()
  }, [filterConfig.filterServiceKioskOnly, services])

  // TODO: options เอามาจากไหนหว่า
  const invoiceTypeOptions = useMemo((): DefaultOptionType[] => {
    return chain([
      {
        name: 'เต็มรูปแบบ',
        id: 1,
      },
    ])
      .map((e): DefaultOptionType => {
        return {
          label: e.name,
          value: e.id,
        }
      })
      .value()
  }, [])

  const packageOptions = useMemo((): DefaultOptionType[] => {
    return chain([
      {
        name: 'Gold Package',
        id: 1,
      },
    ])
      .map((e): DefaultOptionType => {
        return {
          label: e.name,
          value: e.id,
        }
      })
      .value()
  }, [])

  return (
    <Card style={{ marginBottom: 16 }}>
      <Form
        layout="vertical"
        form={form}
        initialValues={filterInitialValues}
        onFinish={handleFinishForm}
        onFieldsChange={handleFormChange}
      >
        <Row gutter={40}>
          <Col xs={24} sm={24} lg={20}>
            <Row gutter={[10, 10]}>
              {filterConfig.filterService && (
                <Col xs={24} lg={8}>
                  <Form.Item name="serviceId" label="ชื่อบริการ" rules={REQUIRED_RULES} required>
                    <Select placeholder="เลือกบริการ" options={serviceOptions} />
                  </Form.Item>
                </Col>
              )}
              {filterConfig.filterInvoiceType && (
                <Col xs={24} lg={8}>
                  <Form.Item name="invoiceTypeId" label="ประเภทใบเสร็จ" rules={REQUIRED_RULES} required>
                    <Select placeholder="เลือกประเภทใบเสร็จ" options={invoiceTypeOptions} />
                  </Form.Item>
                </Col>
              )}
              {filterConfig.filterPackageType && (
                <Col xs={24} lg={8}>
                  <Form.Item name="packageTypeId" label="Package" rules={REQUIRED_RULES} required>
                    <Select placeholder="เลือก Package" options={packageOptions} />
                  </Form.Item>
                </Col>
              )}
              {filterConfig.filterDate && (
                <Col xs={24} lg={8}>
                  <Form.Item name="startDate" label="วันที่ต้องการตรวจสอบการบริการ" rules={REQUIRED_RULES} required>
                    <DatePicker />
                  </Form.Item>
                </Col>
              )}
              {filterConfig.filterMonthYear && (
                <Col xs={24} lg={8}>
                  <Form.Item name="monthYear" label="เดือน-ปี" rules={REQUIRED_RULES} required>
                    <DatePicker.MonthPicker />
                  </Form.Item>
                </Col>
              )}
              {filterConfig.filterDateRange && (
                <Col xs={24} lg={8}>
                  <Form.Item name="rangeDate" label="ช่วงวันที่ที่ต้องการเรียกดูข้อมูล" rules={REQUIRED_RULES} required>
                    <RangePicker />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Col>
          <Col xs={24} sm={24} lg={4}>
            <Row justify="end">
              <Col>
                <Form.Item label=" " style={{ margin: 0, width: '100%' }}>
                  <Button htmlType="submit" style={{ width: 'max-content' }}>
                    ดาวน์โหลดรายงาน
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}
