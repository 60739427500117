import { useCallback, useState } from 'react'

export const useVisible = <T extends Record<string, any> = Record<string, any>>(defaultVisible = false) => {
  const [visible, setVisible] = useState(defaultVisible)
  const [data, setData] = useState<T>()
  const open = useCallback((d?: T) => {
    setData(d)
    setVisible(true)
  }, [])
  const close = useCallback(() => {
    setData(undefined)
    setVisible(false)
  }, [])

  return { visible, open, close, data }
}
