import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, Col, Row, Table, Tag } from 'antd'
import { ColumnType } from 'antd/lib/table'
import dayjs from 'dayjs'
import { styled } from 'goober'
import { chain, compact } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { SearchInput } from '_backend/components/SearchInput'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { BackButton } from 'components/BackButton'
import { Txt } from 'components/Txt'
import { getColumnNumberByPage } from 'helpers/column-helper'
import { backendPaths } from 'paths/backend-path-config'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { IGetKioskRegisterResponse } from 'services/kiosk-register/kiosk-register-response'
import { useGetKioskRegisterListQRY } from 'services/kiosk-register/kiosk-register-service'
import theme from 'theme/goober'

const ViewIcon = styled(FontAwesomeIcon)`
  min-width: 40px;
  color: ${theme.color.lightBluePrimary};
  cursor: pointer;
`
export const PageKioskMemberManagementList = () => {
  const { serviceId } = useParams()

  const [txtSearch, setTxtSearch] = useState<string>()
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)
  const navigate = useNavigate()

  const { data: service } = useGetServiceQRY(Number(serviceId))
  const { data: registerList, isFetching } = useGetKioskRegisterListQRY(Number(serviceId), txtSearch)

  const dataSource = useMemo(() => {
    let items = chain(registerList ?? [])
    if (txtSearch) {
      items = items.filter((e) =>
        compact([e.statusDisplay, e.userDisplay, e.cid, e.tel, e.email, e.productName])
          .join()
          .toLowerCase()
          .includes(txtSearch.toLowerCase()),
      )
    }
    return items.value()
  }, [registerList, txtSearch])

  const handlePageChange = useCallback((page: number, pageSize: number) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }, [])

  const onTableRowEditClick = useCallback(
    (d: IGetKioskRegisterResponse) => {
      navigate(
        backendPaths.serviceKioskMemberManagementDetail({
          routeParam: {
            serviceId: Number(serviceId),
            registerId: d.id,
          },
        }),
      )
    },
    [navigate, serviceId],
  )

  const columns = useMemo(
    (): ColumnType<IGetKioskRegisterResponse>[] => [
      {
        title: 'ลำดับ',
        width: '50px',
        align: 'center',
        render: (_: any, record, index: number) => (
          <Txt ag="body20">{getColumnNumberByPage(currentPage, index, currentPageSize)}</Txt>
        ),
      },
      {
        title: 'สถานะ',
        render: (_: any, record) => (
          <Tag color={record.status === 1 ? 'success' : 'default'}>{record.statusDisplay}</Tag>
        ),
      },
      {
        title: 'ชื่อ-สกุล',
        dataIndex: 'userDisplay',
        render: (v) => v?.trim() ?? '',
      },
      {
        title: 'เลขบัตรประชาชน',
        dataIndex: 'cid',
      },
      {
        title: 'เบอร์ติดต่อ',
        dataIndex: 'tel',
      },
      {
        title: 'Email',
        dataIndex: 'email',
      },
      {
        title: 'ประเภทสินค้า',
        dataIndex: 'productName',
      },
      {
        title: 'ที่อยู่',
        dataIndex: 'addrDisplay',
      },
      {
        title: 'วันที่เริ่มสมาชิก',
        dataIndex: 'approveDate',
        render: (v) => (v ? dayjs(v).format('YYYY-MM-DD HH:mm') : ''),
      },
      {
        dataIndex: 'action',
        width: '80px',
        render: (_: any, record) => <ViewIcon icon={faEye} onClick={onTableRowEditClick.bind(null, record)} />,
      },
    ],
    [currentPage, currentPageSize, onTableRowEditClick],
  )

  const onSearchService = useCallback((value?: string) => {
    setTxtSearch(value)
  }, [])

  return (
    <>
      <ContentHeader title={service?.name} subTitle="Member Management">
        <Row
          gutter={[
            {
              sm: 24,
              xs: 16,
            },
            {
              sm: 16,
              xs: 16,
            },
          ]}
          justify="end"
          align="middle"
        >
          <Col>
            <SearchInput placeholder="ค้นหา" onSearch={onSearchService} />
          </Col>
        </Row>
      </ContentHeader>
      <Card style={{ width: '100%' }}>
        <Table
          scroll={{ x: '100%' }}
          dataSource={dataSource}
          columns={columns}
          loading={isFetching}
          rowKey={'id'}
          pagination={{
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            onChange: handlePageChange,
          }}
        />
        <BackButton to={backendPaths.serviceList()} />
      </Card>
    </>
  )
}
