import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Select } from 'antd'
import { BaseOptionType } from 'antd/lib/select'
import { styled } from 'goober'
import { useCallback, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import theme from 'theme/goober'

const { Option } = Select

export interface ISearchAutoCompleteOption {
  text: string
  value: any
}

interface ISearchAutoCompleteProps {
  options: ISearchAutoCompleteOption[]
  placeholder?: string
  delay?: number
  isLoading?: boolean
  width?: string
  value?: any
  onChange?: (value: any) => void
  onSearch: (q: string) => void
}

const SearchIcon = styled(FontAwesomeIcon)`
  color: ${theme.color.grayPlaceholder};
  font-size: 14px;
  cursor: pointer;
`

export const SearchAutoComplete = (props: ISearchAutoCompleteProps) => {
  const {
    options,
    placeholder = 'ค้นหา',
    delay = 300,
    isLoading = false,
    width = '250px',
    value,
    onChange,
    onSearch,
  } = props
  const [searchValue, setSearchValue] = useState('')

  const handleSearch = useCallback(
    (q: string) => {
      onSearch(q)
      setSearchValue(q)
    },
    [onSearch],
  )

  const handleChange = useCallback(
    (_: any, option: BaseOptionType) => {
      onChange?.(option?.value)
    },
    [onChange],
  )

  const debouncedHandleSearch = useDebouncedCallback(handleSearch, delay)
  const selectOptions = useMemo(
    () =>
      isLoading
        ? [<Option key="loading">Loading...</Option>]
        : options.map((o) => (
            <Option key={o.value} value={o.value}>
              {o.text}
            </Option>
          )),
    [isLoading, options],
  )

  return (
    <div>
      <Select
        showSearch
        value={value}
        placeholder={placeholder}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={debouncedHandleSearch}
        onChange={handleChange}
        notFoundContent={searchValue ? <div>ไม่พบข้อมูล</div> : null}
        allowClear
        style={{ width, textAlign: 'left' }}
      >
        {selectOptions}
      </Select>
      <SearchIcon icon={faSearch} style={{ position: 'absolute', right: '20px', top: '12px' }} />
    </div>
  )
}
