import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Col, Form, notification, Row, Space } from 'antd'
import Table, { ColumnType } from 'antd/lib/table'
import { styled } from 'goober'
import { useCallback, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { BackButton } from 'components/BackButton'
import { Input } from 'components/Input'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { Txt } from 'components/Txt'
import { getColumnNumberByPage } from 'helpers/column-helper'
import { truncateString } from 'helpers/string-helper'
import { backendPaths } from 'paths/backend-path-config'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { IGetSearchServicePropDayOffResponse } from 'services/property-management/property-management-response'
import {
  useDeleteServicePropDayOff,
  useGetSearchServicePropDayOffQRY,
} from 'services/property-management/property-management-service'
import theme from 'theme/goober'

import { ModalSetOffDay } from './ModalSetOffDay'

const Icon = styled(FontAwesomeIcon)`
  color: ${theme.color.lightBluePrimary};
  cursor: pointer;
`

export const PagePropertyManagementDayOffList = () => {
  const query = useParams()
  const { search } = useLocation()
  const querySearch = new URLSearchParams(search)
  const serviceId = Number(query.serviceId)
  const propertyId = Number(query.propertyId)

  const modalConfirm = useModalConfirm()
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)
  const [visible, setVisible] = useState(false)
  const [idDayOff, setIdDayOff] = useState(0)
  const { data: dataSource, isLoading: isSearchServicePropLoading } = useGetSearchServicePropDayOffQRY({
    ServiceId: serviceId,
    ServicePropId: propertyId,
  })
  const { data: service } = useGetServiceQRY(serviceId)
  const { mutateAsync: deleteServicePropDayOff } = useDeleteServicePropDayOff()
  const handlePageChange = useCallback((page: number, pageSize: number) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }, [])

  const onShowAddServiceModal = useCallback((id: number) => {
    setIdDayOff(id)
    setVisible(true)
  }, [])
  const onDismissClick = useCallback(() => {
    setVisible(false)
  }, [])

  const handlePublishedPlatformChange = useCallback(
    (item: IGetSearchServicePropDayOffResponse) => {
      modalConfirm.show({
        type: 'danger',
        title: 'ยืนยันการลบวันหยุด',
        content: `คุณยืนยันที่จะลบวันหยุดวันที่ ${item.dayOff} เวลา ${item.starttime} ถึง ${item.endtime} นี้หรือไม่?`,
        modalProps: {
          width: 700,
        },
        onOk: () => {
          deleteServicePropDayOff(
            { id: item.id },
            {
              onSuccess: () => {
                notification.success({ message: 'สำเร็จ', description: 'ลบข้อมูลสำเร็จ', duration: 2 })
              },
            },
          )
        },
      })
    },
    [deleteServicePropDayOff, modalConfirm],
  )

  const columns = useMemo(
    (): ColumnType<IGetSearchServicePropDayOffResponse>[] => [
      {
        title: 'ลำดับ',
        align: 'center',
        render: (_: any, record: IGetSearchServicePropDayOffResponse, index: number) => (
          <Txt ag="body20">{getColumnNumberByPage(currentPage, index, currentPageSize)}</Txt>
        ),
      },
      {
        title: 'วันที่ปิดทำการ',
        dataIndex: 'dayOffDisplay',
      },
      {
        title: 'ช่วงเวลาปิดทำการ',
        render: (_: any, record: IGetSearchServicePropDayOffResponse, index: number) => (
          <Txt ag="body20">
            {record.starttime} - {record.endtime}
          </Txt>
        ),
      },
      {
        title: 'หมายเหตุ',
        dataIndex: 'remark',
        width: '400px',
        render: (_: any, record: IGetSearchServicePropDayOffResponse) => {
          const { remark } = record
          return <span>{truncateString(remark, 100)}</span>
        },
      },

      {
        title: 'Modify By',
        dataIndex: 'modifystfnm',
      },
      {
        title: 'Modify Date',
        dataIndex: 'modifydatedisplay',
      },
      {
        width: '92px',
        fixed: 'right',
        render: (_: any, record: IGetSearchServicePropDayOffResponse, index: number) => {
          return (
            <Space size={20} direction="horizontal">
              <Icon icon={faEdit} onClick={onShowAddServiceModal.bind(null, record.id)} />
              <Icon icon={faTrashCan} onClick={handlePublishedPlatformChange.bind(null, record)} />
            </Space>
          )
        },
      },
    ],
    [currentPage, handlePublishedPlatformChange, onShowAddServiceModal, currentPageSize],
  )

  return (
    <>
      <ContentHeader title={service?.name} subTitle="Property Day Off" />
      <ContentBody>
        <Card>
          <div>
            <Txt ag="header30">รายละเอียด Property</Txt>
            <Row
              gutter={[
                {
                  sm: 24,
                  xs: 16,
                },
                {
                  sm: 16,
                  xs: 16,
                },
              ]}
              justify="end"
              align="middle"
            >
              <Col>
                <Button block onClick={onShowAddServiceModal.bind(null, 0)}>
                  เพิ่มวันหยุด
                </Button>
              </Col>
            </Row>
          </div>

          <Form.Item label="" name="serviceName">
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
              <Txt ag="body20">ชื่อ Property</Txt>
              <Input placeholder={`${querySearch.get('nameProperty')}`} disabled style={{ width: '30%' }} />
            </div>
          </Form.Item>

          <Table
            dataSource={dataSource}
            columns={columns}
            loading={isSearchServicePropLoading}
            rowKey="id"
            pagination={{
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              onChange: handlePageChange,
            }}
          />
          <BackButton
            to={backendPaths.serviceEncoPropertyManagementList({
              routeParam: {
                serviceId,
              },
            })}
          />
        </Card>
      </ContentBody>
      <ModalSetOffDay visible={visible} onDismiss={onDismissClick} id={idDayOff} servicePropId={propertyId} />
    </>
  )
}
