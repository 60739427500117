import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'
import { ResponseType } from 'services/response-type'

import {
  IApproveKioskRegisterParams,
  IGetKioskRegisterListParams,
  ISubmitKioskRegisterParams,
} from './kiosk-register-params'
import { IGetKioskRegisterResponse } from './kiosk-register-response'

const SEARCH_KIOSK_REGISTER_PATH = '/SearchKioskRegister'
const KIOSK_REGISTER_PATH = '/KioskRegister'
const KIOSK_APPROVE_PATH = '/KioskApprove'

export const useGetKioskRegisterListQRY = (tbServiceId?: number, txtSearch?: string) => {
  const params: IGetKioskRegisterListParams = {}
  if (tbServiceId) {
    params.serviceId = tbServiceId
  }
  if (txtSearch) {
    params.txtSearch = txtSearch
  }
  return useQuery(
    [SEARCH_KIOSK_REGISTER_PATH, params],
    async () => {
      const res = await encoClient.get<ResponseType<IGetKioskRegisterResponse[]>>(`${SEARCH_KIOSK_REGISTER_PATH}`, {
        params,
      })
      return res.data.data
    },
    {
      enabled: !!tbServiceId,
    },
  )
}

export const useGetKioskRegisterQRY = (params: { id?: number }) => {
  return useQuery(
    [KIOSK_REGISTER_PATH, params],
    async () => {
      const res = await encoClient.get<ResponseType<IGetKioskRegisterResponse>>(`${KIOSK_REGISTER_PATH}`, {
        params,
      })
      return res.data.data
    },
    {
      enabled: !!params?.id,
    },
  )
}

export const useSaveKioskRegisterMTT = () => {
  const queryClient = useQueryClient()
  return useMutation<unknown, unknown, ISubmitKioskRegisterParams>(
    async (data) => {
      const res = await encoClient.post<ResponseType<IGetKioskRegisterResponse>>(`${KIOSK_REGISTER_PATH}`, data, {
        headers: { 'Content-Type': ContentType.JSON },
      })
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SEARCH_KIOSK_REGISTER_PATH])
        queryClient.invalidateQueries([KIOSK_REGISTER_PATH])
      },
    },
  )
}

export const useApproveKioskRegisterMTT = () => {
  const queryClient = useQueryClient()
  return useMutation<unknown, unknown, IApproveKioskRegisterParams>(
    async (data) => {
      const res = await encoClient.post<ResponseType<IGetKioskRegisterResponse>>(`${KIOSK_APPROVE_PATH}`, data, {
        headers: { 'Content-Type': ContentType.JSON },
      })
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SEARCH_KIOSK_REGISTER_PATH])
        queryClient.invalidateQueries([KIOSK_REGISTER_PATH])
      },
    },
  )
}
