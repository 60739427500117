import { Button, Card, Col, Row } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { compact } from 'lodash'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { Link, useParams } from 'react-router-dom'

import { SearchInput } from '_backend/components/SearchInput'
import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { BackButton } from 'components/BackButton'
import { Txt } from 'components/Txt'
import { backendPaths } from 'paths/backend-path-config'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { IGetSearchServicePropResponse } from 'services/property-management/property-management-response'

import { TablePropertyManagement } from './TablePropertyManagement'

interface IMenuItem {
  to: string
  onClick?: (to: string) => void
  label?: ReactNode
  disabled?: boolean
}

const makeMenuLabel = (item: IMenuItem): ItemType => {
  return {
    key: `${item.label}-${item.to}`,
    label: (
      <Button
        style={{
          textAlign: 'left',
        }}
        block
        onClick={item.onClick?.bind(null, item.to)}
        type="text"
        size="small"
        disabled={item.disabled}
      >
        <Txt ag="body20" color={item.disabled ? 'grayBorder' : undefined}>
          {item.label}
        </Txt>
      </Button>
    ),
  }
}

export const PagePropertyManagementList = () => {
  const query = useParams()
  const serviceId = Number(query.serviceId)
  const navigate = useNavigate()

  const { data: service } = useGetServiceQRY(Number(serviceId))

  const { mdServiceCatId = 0 } = service ?? {}
  const isKiosk = +mdServiceCatId === 3

  const [txtSearch, setTxtSearch] = useState('')

  const handleSearch = useCallback((value: string) => {
    setTxtSearch(value)
  }, [])

  const onClickMenuItem = useCallback(
    (to: string) => {
      navigate(to)
    },
    [navigate],
  )

  const renderEncoServiceMenuItems = useCallback(
    (record: IGetSearchServicePropResponse): ItemType[] => {
      const { id: servicePropId, name } = record
      const items: IMenuItem[] = compact([
        {
          label: 'Property & Period Setting ',
          to: backendPaths.serviceEncoPropertyManagementEdit({
            routeParam: {
              serviceId: serviceId,
              propertyId: servicePropId,
            },
          }),
          onClick: onClickMenuItem,
        },
        !isKiosk && {
          label: 'Day off  Setting',
          to: backendPaths.serviceEncoPropertyManagementDayOffList({
            routeParam: {
              serviceId: serviceId,
              propertyId: servicePropId,
            },
            queryParam: {
              nameProperty: name,
            },
          }),
          onClick: onClickMenuItem,
        },
      ])
      return items.map(makeMenuLabel)
    },
    [isKiosk, onClickMenuItem, serviceId],
  )

  const newPropertyPath = useMemo(
    () =>
      backendPaths.serviceEncoPropertyManagementNew({
        routeParam: { serviceId: Number(serviceId) },
      }),
    [serviceId],
  )

  return (
    <>
      <ContentHeader title={service?.name} subTitle="Property Management ">
        <Row
          gutter={[
            {
              sm: 24,
              xs: 16,
            },
            {
              sm: 16,
              xs: 16,
            },
          ]}
          justify="end"
          align="middle"
        >
          <Col>
            <SearchInput placeholder="ค้นหา Property ของคุณ" onSearch={handleSearch} />
          </Col>
          <Col>
            <Link to={newPropertyPath} style={{ display: serviceId === 2 ? 'none' : 'block' }}>
              <Button type="primary">New Property</Button>
            </Link>
          </Col>
        </Row>
      </ContentHeader>
      <ContentBody>
        <Card>
          <TablePropertyManagement renderMenuItems={renderEncoServiceMenuItems} txtSearch={txtSearch} />
          <BackButton to={backendPaths.serviceList()} />
        </Card>
      </ContentBody>
    </>
  )
}
