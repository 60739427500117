import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Col, Row, Table, Tag } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { styled } from 'goober'
import { chain } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { SearchInput } from '_backend/components/SearchInput'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { BackButton } from 'components/BackButton'
import { Txt } from 'components/Txt'
import { getColumnNumberByPage } from 'helpers/column-helper'
import { backendPaths } from 'paths/backend-path-config'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { IGetKioskMasterProductResponse } from 'services/kiosk-master/kiosk-master-response'
import { useGetKioskMasterProductListQRY } from 'services/kiosk-master/kiosk-master-service'
import theme from 'theme/goober'

const EyeIcon = styled(FontAwesomeIcon)`
  min-width: 40px;
  color: ${theme.color.lightBluePrimary};
  cursor: pointer;
`
export const PageKioskMasterProductList = () => {
  const queryParams = useParams()
  const serviceId = +(queryParams.serviceId || 0)

  const [txtSearch, setTxtSearch] = useState<string>()
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)

  const { data: service } = useGetServiceQRY(Number(serviceId))
  const { data: masterProducts, isFetching } = useGetKioskMasterProductListQRY(Number(serviceId), txtSearch)

  const dataSource = useMemo(() => {
    let items = chain(masterProducts ?? [])
    if (txtSearch) {
      items = items.filter((e) => e.productName.toLowerCase().includes(txtSearch.toLowerCase()))
    }
    return items.orderBy(['productName'], ['asc']).value()
  }, [masterProducts, txtSearch])

  const handlePageChange = useCallback((page: number, pageSize: number) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }, [])

  const renderTagActive = useCallback((_isActive: boolean, record: IGetKioskMasterProductResponse) => {
    if (record.canceldate) {
      return <Tag color="error">Cancelled</Tag>
    }

    if (record.isActive) {
      return <Tag color="success">Active</Tag>
    }
    return <Tag color="default">Inactive</Tag>
  }, [])

  const columns = useMemo(
    (): ColumnType<IGetKioskMasterProductResponse>[] => [
      {
        title: 'ลำดับ',
        width: '50px',
        align: 'center',
        render: (_: any, record, index: number) => (
          <Txt ag="body20">{getColumnNumberByPage(currentPage, index, currentPageSize)}</Txt>
        ),
      },
      {
        title: 'ประเภทสินค้า',
        dataIndex: 'productName',
      },
      {
        title: 'สถานะ',
        dataIndex: 'isActive',
        render: renderTagActive,
      },
      {
        title: 'Modify Date',
        dataIndex: 'modifydateDisplay',
      },
      {
        title: 'Modify By',
        dataIndex: 'modifystfDisplay',
      },
      {
        dataIndex: 'action',
        width: '80px',
        render: (_: any, record) => (
          <Link
            to={backendPaths.serviceKioskMasterProductEdit({
              routeParam: {
                serviceId: record.tbServiceId,
                productId: record.id,
              },
            })}
          >
            <EyeIcon icon={faEdit} />
          </Link>
        ),
      },
    ],
    [renderTagActive, currentPage, currentPageSize],
  )

  const onSearchService = useCallback((value?: string) => {
    setTxtSearch(value)
  }, [])

  return (
    <>
      <ContentHeader title={service?.name} subTitle="Master Data">
        <Row
          gutter={[
            {
              sm: 24,
              xs: 16,
            },
            {
              sm: 16,
              xs: 16,
            },
          ]}
          justify="end"
          align="middle"
        >
          <Col>
            <SearchInput placeholder="ค้นหา" onSearch={onSearchService} />
          </Col>
          <Col>
            <Link
              to={backendPaths.serviceKioskMasterProductNew({
                routeParam: {
                  serviceId: Number(serviceId),
                },
              })}
            >
              <Button type="primary">เพิ่ม</Button>
            </Link>
          </Col>
        </Row>
      </ContentHeader>
      <Card style={{ width: '100%' }}>
        <Table
          scroll={{ x: '100%' }}
          dataSource={dataSource}
          columns={columns}
          loading={isFetching}
          rowKey={'id'}
          pagination={{
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            onChange: handlePageChange,
          }}
        />
        <BackButton to={backendPaths.serviceList()} />
      </Card>
    </>
  )
}
