import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query'
import { identity, map, pickBy } from 'lodash'
import { useCallback, useMemo } from 'react'

import { EncoServiceContentFormValues } from '_backend/modules/service/EncoServiceContentForm'
import { EncoServiceFormValues } from '_backend/modules/service/EncoServiceForm'
import { IKioskServiceFormValues } from '_backend/modules/service/KioskServiceForm'
import { OtherServiceFormValues } from '_backend/modules/service/OtherServiceForm'
import { GET_USER_INFO_PATH } from 'services/auth/auth-service'
import { useMutationServiceEmergencyContactMTT } from 'services/emergency-contact/emergency-contact-service-service'
import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'
import { ISearchOrderResponse } from 'services/order-response/order-response-service-response'
import { useMutationServiceContentMTT } from 'services/service-content/service-content-service-service'
import { useMutationServiceLinkMTT } from 'services/service-link/service-link-service-service'
import { ISubmitServiceLocation } from 'services/service-location/service-location-service-param'
import { useMutationServiceLocationsMTT } from 'services/service-location/service-location-service-service'

import { ResponseType } from '../response-type'

import {
  GetServiceContactStatusFilterEnum,
  ICheckPromotionCodeParams,
  IGetEmployeePromotionParams,
  IGetServiceSlotSelectParams,
  IGetServicesParams,
  IMutationServiceParams,
  IMutationSetServiceDisplayParams,
  IMutationSetServiceDisplayRecNoParams,
  IMutationSetServiceRecNoParams,
  ISaveServiceOrderBookingParams,
  ISaveServiceRequestParams,
  ISubmitServiceKioskParams,
  ISubmitServiceOtherParams,
  ISubmitServiceOzoneParams,
  ISubmitServiceStadiumParams,
} from './enco-service-param'
import {
  IMasterServiceType,
  IMutationServiceContentResponse,
  IServiceData,
  IMutationSetServiceRecNoResponse,
  IGetServiceSlotSelectResponse,
  IPromotionCodeResponse,
  ISaveServiceRequestResponse,
  ISearchServiceItemResponse,
  IGetLocationOzoneBookingResponse,
} from './enco-service-response'

const SERVICE = '/Service'
export const GET_SERVICES = '/GetServices'
const GET_MASTER_SERVICE_TYPE = '/GetMasterServiceType'

const SET_SERVICE_RECNO = '/SetServiceRecno'
const GET_SERVICE_SLOT_SELECT = '/ServiceSlotSelect'
const SAVE_SERVICE_ORDER_BOOKING = '/OrderBooking'
const CHECK_PROMOTION_CODE_PATH = '/CheckPromoCode'
const GET_EMPLOYEE_PROMOTION_PATH = '/GetPromoCodeEmployee'
const SAVE_SERVICE_REQUEST_PATH = '/Order'
const GET_LOCATION_OZONE_BOOKING = '/GetLocationOzoneBooking'
const SEARCH_SERVICE_ITEM = '/SearchServiceItem'
const GET_APP_CONFIG = '/GetAppConfig'
const SAVE_APP_CONFIG = '/SaveAppConfig'
const SET_SERVICE_DISPLAY_RECNO = '/SetServiceDisplayRecno'
const SET_SERVICE_DISPLAY = '/ServiceDisplay'

export const ENCO_TYPE_OZONE_ID = 2

export const useGetMasterServiceTypeQRY = () => {
  return useQuery([GET_MASTER_SERVICE_TYPE], async () => {
    const response = await encoClient.get<ResponseType<IMasterServiceType[]>>(GET_MASTER_SERVICE_TYPE)
    return response.data.data
  })
}

export const useGetServicesQRY = (params: IGetServicesParams) => {
  const normalizeParams = pickBy(params, identity)
  return useQuery([GET_SERVICES, normalizeParams], async () => {
    const response = await encoClient.post<ResponseType<IServiceData[]>>(
      GET_SERVICES,
      {
        ...normalizeParams,
        isActive: normalizeParams.isActive || GetServiceContactStatusFilterEnum.ALL,
      },
      {
        headers: { 'Content-Type': ContentType.FORM_DATA },
      },
    )
    return response.data.data
  })
}

export const useGetServiceQRY = (serviceId?: number) => {
  return useQuery(
    [GET_SERVICES, { serviceId }],
    async () => {
      const response = await encoClient.get<ResponseType<IServiceData>>(`${GET_SERVICES}`, {
        params: {
          id: serviceId,
        },
      })
      return response.data.data
    },
    {
      enabled: !!serviceId,
    },
  )
}

export const useMutationServiceMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: IMutationServiceParams) => {
      const paramsFixValues: IMutationServiceParams = {
        ...params,
      }
      const response = await encoClient.post<ResponseType<IMutationServiceContentResponse>>(SERVICE, paramsFixValues, {
        headers: {
          'Content-Type': ContentType.FORM_DATA,
        },
      })

      return response.data.data
    },
    {
      onSuccess: (response, params) => {
        const { Id: serviceId } = params
        queryClient.invalidateQueries([GET_SERVICES])
        queryClient.invalidateQueries([SERVICE, { serviceId }])
      },
    },
  )
}

export const useMutationSetServiceRecNoMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: IMutationSetServiceRecNoParams) => {
      const response = await encoClient.post<ResponseType<IMutationSetServiceRecNoResponse>>(
        SET_SERVICE_RECNO,
        params,
        {
          headers: {
            'Content-Type': ContentType.FORM_DATA,
          },
        },
      )

      return response.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_SERVICES])
      },
    },
  )
}

export const useMutationSetServiceDisplayRecNoMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: IMutationSetServiceDisplayRecNoParams) => {
      const response = await encoClient.post<ResponseType<IMutationSetServiceRecNoResponse>>(
        SET_SERVICE_DISPLAY_RECNO,
        params,
        {
          headers: {
            'Content-Type': ContentType.FORM_DATA,
          },
        },
      )

      return response.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_SERVICES])
      },
    },
  )
}

export const useMutationSetServiceDisplayMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: IMutationSetServiceDisplayParams) => {
      const response = await encoClient.post<ResponseType<IMutationSetServiceRecNoResponse>>(
        SET_SERVICE_DISPLAY,
        params,
        {
          headers: {
            'Content-Type': ContentType.FORM_DATA,
          },
        },
      )

      return response.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_SERVICES])
      },
    },
  )
}

export const useSubmitEncoService = (
  serviceId: number,
  serviceTypeId: number,
  isOzone: boolean,
  mdServiceCatId?: number,
) => {
  const { mutateAsync: mutateService, isLoading: isServiceSubmitting } = useMutationServiceMTT()
  const { mutateAsync: mutateServiceLocations, isLoading: isServiceLocationsSubmitting } =
    useMutationServiceLocationsMTT()

  const isSubmitting = useMemo(() => {
    return isServiceSubmitting || isServiceLocationsSubmitting
  }, [isServiceLocationsSubmitting, isServiceSubmitting])

  const onSubmitEncoService = useCallback(
    async (values: EncoServiceFormValues) => {
      const {
        serviceName,
        propUnitName,
        quotationAbbr,
        active,
        icon = '',
        bannerMobile = '',
        bannerDesktop = '',

        memberFunction = 0,
        memberBtnName = '',

        bookingFunction = 0,
        bookingBtnName = '',
        minDateBooking = 0,
        maxPostpone = 0,

        sponsorFunction = 0,
        sponsorBtnName = '',

        serviceLocationFunc = 0,
        serviceLocations = [],
      } = values

      const serviceOzoneParams: ISubmitServiceOzoneParams = {
        Id: serviceId,
        Name: serviceName,
        MdServiceTypeId: serviceTypeId,
        MdServiceCatId: mdServiceCatId,
        icon,
        CoverImgMobile: bannerMobile,
        CoverImgWeb: bannerDesktop,
        Active: active,

        // non common
        PropUnitName: propUnitName,
        QuotationAbbr: quotationAbbr,
        BookingFunction: bookingFunction,
        BookingBtnName: bookingBtnName,
        MinDateBooking: minDateBooking,
        MaxPostpone: maxPostpone,
        SponsorFunction: sponsorFunction,
        SponsorBtnName: sponsorFunction ? sponsorBtnName || '' : '',
        ServiceLocationFunc: serviceLocationFunc, // 0=False, 1=True, สถานที่ให้บริการ (Service Location) ใช้เฉพาะ service ozone

        // fix
        ContactFunction: 0,
        MemberFunction: 0,
        MemberBtnName: null,
      }

      const serviceStadiumParams: ISubmitServiceStadiumParams = {
        Id: serviceId,
        Name: serviceName,
        MdServiceTypeId: serviceTypeId,
        MdServiceCatId: mdServiceCatId,
        icon,
        CoverImgMobile: bannerMobile,
        CoverImgWeb: bannerDesktop,
        Active: active,

        // non common
        PropUnitName: propUnitName,
        QuotationAbbr: quotationAbbr,
        BookingFunction: bookingFunction,
        BookingBtnName: bookingFunction ? bookingBtnName : '',
        MinDateBooking: bookingFunction ? minDateBooking : 0,
        MaxPostpone: bookingFunction ? maxPostpone : 0,
        MemberFunction: memberFunction,
        MemberBtnName: memberFunction ? memberBtnName : '',
        SponsorFunction: sponsorFunction,
        SponsorBtnName: sponsorFunction ? sponsorBtnName : '',

        // fix
        ContactFunction: 0,
        ServiceLocationFunc: 0, // 0=False, 1=True, สถานที่ให้บริการ (Service Location) ใช้เฉพาะ service ozone
      }
      if (isOzone) {
        const serviceResponse = await mutateService(serviceOzoneParams)
        if (serviceLocationFunc) {
          await mutateServiceLocations({
            Location: map(serviceLocations, (serviceLocation): ISubmitServiceLocation => {
              return {
                IsCheck: serviceLocation.isCheck,
                ServiceLocationId: serviceLocation.serviceLocationId,
              }
            }),
          })
        }
        return {
          service: serviceResponse,
        }
      }

      return {
        service: await mutateService(serviceStadiumParams),
      }
    },
    [isOzone, mdServiceCatId, mutateService, mutateServiceLocations, serviceId, serviceTypeId],
  )

  return { isSubmitting, onSubmitEncoService }
}

export const useSubmitOtherService = (serviceId: number, serviceTypeId: number, mdServiceCatId?: number) => {
  const { mutateAsync: mutateService, isLoading: isServiceSubmitting } = useMutationServiceMTT()
  const { mutateAsync: mutateServiceContent, isLoading: isServiceContentSubmitting } = useMutationServiceContentMTT()
  const { mutateAsync: mutateServiceEmergencyContact, isLoading: isServiceEmergencyContactSubmitting } =
    useMutationServiceEmergencyContactMTT()
  const { mutateAsync: mutateServiceContactLink, isLoading: isServiceContactLinkSubmitting } =
    useMutationServiceLinkMTT()

  const isSubmitting = useMemo(() => {
    return (
      isServiceSubmitting ||
      isServiceContentSubmitting ||
      isServiceEmergencyContactSubmitting ||
      isServiceContactLinkSubmitting
    )
  }, [
    isServiceContactLinkSubmitting,
    isServiceContentSubmitting,
    isServiceEmergencyContactSubmitting,
    isServiceSubmitting,
  ])

  const onSubmitOtherService = useCallback(
    async (values: OtherServiceFormValues) => {
      const {
        serviceName,
        serviceContentId = 0,
        serviceContentDetail,
        contactFunction,
        active,
        autolink = '',
        icon = '',
        bannerMobile = '',
        bannerDesktop = '',
        emergencyContacts = [],
        serviceLinks = [],
      } = values

      const serviceParams: ISubmitServiceOtherParams = {
        Id: serviceId,
        Name: serviceName,
        MdServiceTypeId: serviceTypeId,
        MdServiceCatId: mdServiceCatId,
        AutoLink: autolink,
        icon,
        CoverImgMobile: bannerMobile,
        CoverImgWeb: bannerDesktop,
        ContactFunction: contactFunction,
        Active: active,

        // fix value
        PropUnitName: '',
        QuotationAbbr: '',
        MemberFunction: 0,
        MemberBtnName: null,
        BookingFunction: 0,
        BookingBtnName: null,
        SponsorFunction: 0,
        SponsorBtnName: null,
        MinDateBooking: 0,
        MaxPostpone: 0,
        ServiceLocationFunc: 0,
      }

      try {
        const serviceResult = await mutateService(serviceParams)
        const [serviceContentResult, serviceEmergencyContactResult, serviceContactLinkResult] = await Promise.all([
          mutateServiceContent({
            ServiceId: serviceResult.id,
            serviceContent: [
              {
                id: serviceContentId,
                funct: null,
                topic: '',
                contenttx: serviceContentDetail,
              },
            ],
          }),
          mutateServiceEmergencyContact({
            ServiceId: serviceResult.id,
            emergencyContact: emergencyContacts,
          }),
          mutateServiceContactLink({
            ServiceId: serviceResult.id,
            serviceLink: serviceLinks,
          }),
        ])

        return {
          service: serviceResult,
          serviceContent: serviceContentResult,
          serviceEmergencyContact: serviceEmergencyContactResult,
          serviceContactLink: serviceContactLinkResult,
        }
      } catch (error) {
        return Promise.reject(error)
      }
    },
    [
      serviceId,
      serviceTypeId,
      mdServiceCatId,
      mutateService,
      mutateServiceContent,
      mutateServiceEmergencyContact,
      mutateServiceContactLink,
    ],
  )

  return { isSubmitting, onSubmitOtherService }
}

export const useSubmitKioskService = (serviceId: number, serviceTypeId: number, mdServiceCatId: number) => {
  const { mutateAsync: mutateService, isLoading: isServiceSubmitting } = useMutationServiceMTT()

  const isSubmitting = useMemo(() => {
    return isServiceSubmitting
  }, [isServiceSubmitting])

  const onSubmitKioskService = useCallback(
    async (values: IKioskServiceFormValues) => {
      const {
        serviceName,
        propUnitName,
        active,
        icon = '',
        coverImgMobile = '',

        bookingFunction = 0,
        bookingBtnName = '',
        minDateBooking = 0,
        maxBookingPerDay = 0,
        maxBookingPerWeek = 0,
      } = values

      const serviceStadiumParams: ISubmitServiceKioskParams = {
        Id: serviceId,
        Name: serviceName,
        MdServiceTypeId: serviceTypeId,
        MdServiceCatId: mdServiceCatId,
        icon,
        CoverImgMobile: coverImgMobile,
        CoverImgWeb: '',
        Active: active,

        // non common
        PropUnitName: propUnitName,
        BookingFunction: bookingFunction,
        BookingBtnName: bookingFunction ? bookingBtnName : '',
        MinDateBooking: bookingFunction ? minDateBooking : 0,
        MaxBookingPerDay: maxBookingPerDay,
        MaxBookingPerWeek: maxBookingPerWeek,

        // fix
        QuotationAbbr: '',
        ContactFunction: 0,
        ServiceLocationFunc: 0, // 0=False, 1=True, สถานที่ให้บริการ (Service Location) ใช้เฉพาะ service ozone
        MaxPostpone: 0,
        MemberFunction: 0,
        MemberBtnName: null,
        SponsorFunction: 0,
        SponsorBtnName: null,
      }

      return {
        service: await mutateService(serviceStadiumParams),
      }
    },
    [mdServiceCatId, mutateService, serviceId, serviceTypeId],
  )

  return { isSubmitting, onSubmitKioskService }
}

export const useSubmitEncoServiceContentsMTT = (serviceId: number) => {
  const { mutateAsync: mutateServiceContent, isLoading: isServiceContentSubmitting } = useMutationServiceContentMTT()
  const { mutateAsync: mutateServiceEmergencyContact, isLoading: isServiceEmergencyContactSubmitting } =
    useMutationServiceEmergencyContactMTT()

  const isSubmitting = useMemo(() => {
    return isServiceContentSubmitting || isServiceEmergencyContactSubmitting
  }, [isServiceContentSubmitting, isServiceEmergencyContactSubmitting])

  const onSubmitEncoServiceContents = useCallback(
    async (values: EncoServiceContentFormValues) => {
      const { emergencyContacts = [], serviceContents } = values

      try {
        if (!serviceId) {
          throw new Error('serviceId is null')
        }
        const [serviceContentResult, serviceEmergencyContactResult] = await Promise.all([
          mutateServiceContent({
            ServiceId: serviceId,
            serviceContent: serviceContents.map((serviceContent) => {
              return {
                id: serviceContent.id,
                contenttx: serviceContent.contenttx,
                funct: serviceContent.funct,
                topic: serviceContent.topic,
              }
            }),
          }),
          mutateServiceEmergencyContact({
            ServiceId: serviceId,
            emergencyContact: emergencyContacts,
          }),
        ])

        return {
          serviceContent: serviceContentResult,
          serviceEmergencyContact: serviceEmergencyContactResult,
        }
      } catch (error) {
        return Promise.reject(error)
      }
    },
    [mutateServiceContent, mutateServiceEmergencyContact, serviceId],
  )

  return { isSubmitting, onSubmitEncoServiceContents }
}

export const useGetServiceSlotSelectQRY = (params: IGetServiceSlotSelectParams) => {
  return useQuery(
    [GET_SERVICE_SLOT_SELECT, params],
    async () => {
      const response = await encoClient.post<ResponseType<IGetServiceSlotSelectResponse[]>>(
        GET_SERVICE_SLOT_SELECT,
        params,
        {
          headers: { 'Content-Type': ContentType.FORM_DATA },
        },
      )

      return response.data.data
    },
    {
      enabled: !!params.servicePropId,
    },
  )
}

export const useSaveOrderBookingMTT = () => {
  const queryClient = useQueryClient()
  return useMutation<ISearchOrderResponse, unknown, ISaveServiceOrderBookingParams>(
    async (data) => {
      const res = await encoClient.post<ResponseType<ISearchOrderResponse>>(`${SAVE_SERVICE_ORDER_BOOKING}`, data, {
        headers: { 'Content-Type': ContentType.JSON },
      })
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_USER_INFO_PATH])
      },
    },
  )
}

export const useCheckPromotionCodeMTT = () => {
  return useMutation<IPromotionCodeResponse, unknown, ICheckPromotionCodeParams>(async (data) => {
    const res = await encoClient.post<ResponseType<IPromotionCodeResponse>>(`${CHECK_PROMOTION_CODE_PATH}`, data, {
      headers: { 'Content-Type': ContentType.FORM_DATA },
    })
    return res.data.data
  })
}

export const useGetEmployeePromotionCodeQRY = (params: IGetEmployeePromotionParams) => {
  return useQuery([GET_EMPLOYEE_PROMOTION_PATH, params], async () => {
    try {
      const response = await encoClient.get<ResponseType<IPromotionCodeResponse[]>>(GET_EMPLOYEE_PROMOTION_PATH, {
        params,
      })
      return response.data.data
    } catch (e) {
      return []
    }
  })
}

export const useSavServiceRequestMTT = () => {
  return useMutation(async (data: ISaveServiceRequestParams) => {
    const res = await encoClient.post<ResponseType<ISaveServiceRequestResponse>>(SAVE_SERVICE_REQUEST_PATH, data)
    return res.data.data
  })
}

export const useGetLocationOzoneBookingQtyQRY = () => {
  return useQuery([GET_LOCATION_OZONE_BOOKING], async () => {
    const res = await encoClient.get<ResponseType<IGetLocationOzoneBookingResponse[]>>(GET_LOCATION_OZONE_BOOKING)
    return res.data.data
  })
}

export const useSearchServiceItemQRY = (options?: UseQueryOptions<ISearchServiceItemResponse[]>) => {
  return useQuery<ISearchServiceItemResponse[]>(
    [SEARCH_SERVICE_ITEM],
    async () => {
      const res = await encoClient.post<ResponseType<ISearchServiceItemResponse[]>>(SEARCH_SERVICE_ITEM, {
        ServiceId: 2,
        itemTypeId: 2,
        isActive: 1,
      })
      return res.data.data
    },
    {
      staleTime: 1 * 1000, // 1 min
      ...options,
    },
  )
}

export const useGetAppConfigQRY = () => {
  return useQuery([GET_APP_CONFIG], async () => {
    const res = await encoClient.get<ResponseType<string>>(GET_APP_CONFIG)
    return res.data.data
  })
}

export const useSaveAppConfigMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (suggestMsg: string) => {
      return encoClient.post(SAVE_APP_CONFIG, {
        suggestMsg,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_APP_CONFIG])
      },
    },
  )
}
