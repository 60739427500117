import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Col, notification, Row, Space, Table } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { styled } from 'goober'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { BackButton } from 'components/BackButton'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { Txt } from 'components/Txt'
import { getColumnNumberByPage } from 'helpers/column-helper'
import { useVisible } from 'helpers/modal-helper'
import { backendPaths } from 'paths/backend-path-config'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { IGetKioskDayOffResponse } from 'services/kiosk-day-off/kiosk-day-off-response'
import { useDeleteKioskDayOffMTT, useGetKioskDayOffListQRY } from 'services/kiosk-day-off/kiosk-day-off-service'
import theme from 'theme/goober'

import { ModalKioskDayOff } from './ModalKioskDayOff'

const EyeIcon = styled(FontAwesomeIcon)`
  min-width: 40px;
  color: ${theme.color.lightBluePrimary};
  cursor: pointer;
`
export const PageKioskDayOffList = () => {
  const queryParams = useParams()
  const serviceId = +(queryParams.serviceId || 0)

  const [txtSearch /* setTxtSearch */] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)

  const { data: service } = useGetServiceQRY(Number(serviceId))
  const { data: dataSource, isFetching } = useGetKioskDayOffListQRY(Number(serviceId), txtSearch)
  const { mutate: deleteKioskDayOff } = useDeleteKioskDayOffMTT()

  const modalConfirm = useModalConfirm()
  const modalKioskDayOffVisible = useVisible()
  const [editKioskDayOffId, setEditKioskDayOffId] = useState<number>()

  const handlePageChange = useCallback((page: number, pageSize: number) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }, [])

  const onModalKioskDayOffDismissClick = useCallback(() => {
    modalKioskDayOffVisible.close()
    setEditKioskDayOffId(undefined)
  }, [modalKioskDayOffVisible])

  const onKioskPeriodEditClick = useCallback(
    (d: IGetKioskDayOffResponse) => {
      setEditKioskDayOffId(d.id)

      modalKioskDayOffVisible.open()
    },
    [modalKioskDayOffVisible],
  )

  const onKioskPeriodDeleteClick = useCallback(
    (d: IGetKioskDayOffResponse) => {
      modalConfirm.show({
        type: 'danger',
        title: 'ยืนยันการลบวันหยุด',
        content: 'คุณยืนยันที่จะลบวันหยุดใช่หรือไม่ ?',
        onOk: () => {
          deleteKioskDayOff(d.id, {
            onSuccess() {
              notification.success({ message: 'สำเร็จ', description: 'ลบวันหยุดสำเร็จ', duration: 2 })
            },
          })
        },
      })
    },
    [deleteKioskDayOff, modalConfirm],
  )

  const columns = useMemo(
    (): ColumnType<IGetKioskDayOffResponse>[] => [
      {
        title: 'ลำดับ',
        width: '50px',
        align: 'center',
        render: (_: any, record: IGetKioskDayOffResponse, index: number) => (
          <Txt ag="body20">{getColumnNumberByPage(currentPage, index, currentPageSize)}</Txt>
        ),
      },
      {
        title: 'วันที่เริ่มต้นปิดทำการ',
        dataIndex: 'startDateDisplay',
      },
      {
        title: 'วันที่สิ้นสุดปิดทำการ',
        dataIndex: 'endDateDisplay',
      },
      {
        title: 'หมายเหตุ',
        dataIndex: 'remark',
      },
      {
        title: 'Modify Date',
        dataIndex: 'modifydateDisplay',
      },
      {
        title: 'Modify By',
        dataIndex: 'modifystfDisplay',
      },
      {
        dataIndex: 'action',
        width: '80px',
        render: (_: any, record: IGetKioskDayOffResponse) => (
          <Space size={0} direction="horizontal">
            <EyeIcon icon={faEdit} onClick={onKioskPeriodEditClick.bind(null, record)} />
            <EyeIcon icon={faTrashCan} onClick={onKioskPeriodDeleteClick.bind(null, record)} />
          </Space>
        ),
      },
    ],
    [currentPage, currentPageSize, onKioskPeriodDeleteClick, onKioskPeriodEditClick],
  )

  // const onSearchService = useCallback((value?: string) => {
  //   if (value) {
  //     setTxtSearch(value)
  //   }
  // }, [])

  return (
    <>
      <ContentHeader title={service?.name} subTitle="Day Off">
        <Row
          gutter={[
            {
              sm: 24,
              xs: 16,
            },
            {
              sm: 16,
              xs: 16,
            },
          ]}
          justify="end"
          align="middle"
        >
          <Col>
            <Button type="primary" onClick={modalKioskDayOffVisible.open}>
              เพิ่มวันหยุด
            </Button>
          </Col>
        </Row>
      </ContentHeader>
      <Card style={{ width: '100%' }}>
        <Table
          scroll={{ x: '100%' }}
          dataSource={dataSource}
          columns={columns}
          loading={isFetching}
          rowKey={'id'}
          pagination={{
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            onChange: handlePageChange,
          }}
        />
        <BackButton to={backendPaths.serviceList()} />
      </Card>

      <ModalKioskDayOff
        serviceId={serviceId}
        kioskDayOffId={editKioskDayOffId}
        visible={modalKioskDayOffVisible.visible}
        onDismiss={onModalKioskDayOffDismissClick}
        onConfirm={onModalKioskDayOffDismissClick}
      />
    </>
  )
}
