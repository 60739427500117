import { Button, Col, Form, Row, Space } from 'antd'
import { styled } from 'goober'
import { useCallback, useEffect, useMemo } from 'react'

import { AppModal } from 'components/AppModal'
import { Input, TextArea } from 'components/Input'
import { Txt } from 'components/Txt'
import { IApproveKioskRegisterParams } from 'services/kiosk-register/kiosk-register-params'
import { EnumKioskRegisterApproveId } from 'services/kiosk-register/kiosk-register-response'

const InputDescription = styled(TextArea)`
  height: 106px !important;
  min-height: 106px !important;
  max-height: 106px !important;
  textarea {
    resize: none;
  }
`

export type ModalConfirmKioskMemberRegisterApproveFormValues = IApproveKioskRegisterParams

interface IModalConfirmKioskMemberRegisterApproveProps {
  visible: boolean
  initialValues?: ModalConfirmKioskMemberRegisterApproveFormValues
  onDismiss: () => void
  onConfirm: (values: ModalConfirmKioskMemberRegisterApproveFormValues) => void
}

export const ModalConfirmKioskMemberRegisterApprove = (props: IModalConfirmKioskMemberRegisterApproveProps) => {
  const { initialValues: preInitialValues, visible, onDismiss, onConfirm } = props

  const [form] = Form.useForm<ModalConfirmKioskMemberRegisterApproveFormValues>()

  const initialValues = useMemo(() => {
    const temp = { ...preInitialValues }
    temp.Id ??= 0
    temp.IsApprove ??= EnumKioskRegisterApproveId.APPROVED
    temp.ApproveNote ??= ''
    return temp
  }, [preInitialValues])

  const isReject = useMemo(
    () => initialValues.IsApprove === EnumKioskRegisterApproveId.REJECT,
    [initialValues.IsApprove],
  )

  const textByStatus = useMemo(() => {
    let title = ''
    let content = ''
    if (initialValues.IsApprove === EnumKioskRegisterApproveId.APPROVED) {
      title = 'ยืนยันการอนุมัติ'
      content = 'ยืนยันการ "อนุมัติ" ใช่หรือไม่ ?'
    } else if (initialValues.IsApprove === EnumKioskRegisterApproveId.REJECT) {
      title = 'ยืนยันการปฏิเสธ'
      content = 'ยืนยันการ ปฏิเสธ" ใช่หรือไม่ ?'
    }

    return {
      title,
      content,
    }
  }, [initialValues.IsApprove])

  const handleDismiss = useCallback(() => {
    onDismiss()
  }, [onDismiss])

  const onSubmit = useCallback(
    (values: ModalConfirmKioskMemberRegisterApproveFormValues) => {
      onConfirm({ ...values })
    },
    [onConfirm],
  )

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues)
    }
  }, [form, initialValues])

  return (
    <AppModal visible={visible} className="custom-confirm-modal" destroyOnClose forceRender>
      <Form form={form} onFinish={onSubmit} layout="vertical" autoComplete="off">
        <AppModal.Header onCloseClick={handleDismiss}>{textByStatus.title}</AppModal.Header>
        <AppModal.Body>
          <Row gutter={24} justify="center">
            {/* keep values */}
            <Form.Item name="Id" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="IsApprove" hidden>
              <Input />
            </Form.Item>
            {/* keep values */}

            <Col span={24}>
              <Space direction="vertical" align="center" style={{ width: '100%' }}>
                <Txt ag="body20">{textByStatus.content}</Txt>
                {isReject && (
                  <Txt ag="body20" style={{ textAlign: 'center' }}>
                    กรุณาระบุเหตุผล
                  </Txt>
                )}
              </Space>
            </Col>
            {isReject && (
              <Col span={24}>
                <Form.Item
                  label="เหตุผลการปฏิเสธ"
                  name="ApproveNote"
                  required
                  rules={[
                    {
                      type: 'string',
                      required: true,
                      message: 'โปรดกรอกเหตุผลการปฏิเสธ',
                    },
                  ]}
                >
                  <InputDescription placeholder="กรอกเหตุผลการปฏิเสธ" maxLength={1000} showCount />
                </Form.Item>
              </Col>
            )}
          </Row>
        </AppModal.Body>
        <AppModal.Footer>
          {isReject && (
            <>
              <Button onClick={handleDismiss} type="primary">
                ยกเลิก
              </Button>
              <Button htmlType="submit" danger>
                ยืนยัน
              </Button>
            </>
          )}

          {!isReject && (
            <>
              <Button onClick={handleDismiss}>ยกเลิก</Button>
              <Button type="primary" htmlType="submit">
                ยืนยัน
              </Button>
            </>
          )}
        </AppModal.Footer>
      </Form>
    </AppModal>
  )
}
