import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'
import { ResponseType } from 'services/response-type'

import { IGetKioskDayOffListParams, ISubmitKioskDayOffParams } from './kiosk-day-off-params'
import { IGetKioskDayOffResponse } from './kiosk-day-off-response'

const SEARCH_SERVICE_DAY_OFF_PATH = '/SearchServiceDayOff'
const SERVICE_DAY_OFF_PATH = '/ServiceDayOff'

export const useGetKioskDayOffListQRY = (tbServiceId?: number, txtSearch?: string) => {
  const params: IGetKioskDayOffListParams = {}
  if (tbServiceId) {
    params.serviceId = tbServiceId
  }
  if (txtSearch) {
    params.txtSearch = txtSearch
  }
  return useQuery(
    [SEARCH_SERVICE_DAY_OFF_PATH, params],
    async () => {
      const res = await encoClient.get<ResponseType<IGetKioskDayOffResponse[]>>(`${SEARCH_SERVICE_DAY_OFF_PATH}`, {
        params,
      })
      return res.data.data
    },
    {
      enabled: !!tbServiceId,
    },
  )
}

export const useGetKioskDayOffMTT = (id?: number) => {
  return useQuery(
    [SERVICE_DAY_OFF_PATH, { id }],
    async () => {
      const res = await encoClient.get<ResponseType<IGetKioskDayOffResponse>>(`${SERVICE_DAY_OFF_PATH}`, {
        params: { id },
      })
      return res.data.data
    },
    {
      enabled: !!id,
    },
  )
}

export const useSaveKioskDayOffMTT = () => {
  const queryClient = useQueryClient()
  return useMutation<unknown, unknown, ISubmitKioskDayOffParams>(
    async (data) => {
      const res = await encoClient.post<ResponseType<IGetKioskDayOffResponse>>(`${SERVICE_DAY_OFF_PATH}`, data, {
        headers: { 'Content-Type': ContentType.JSON },
      })
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SEARCH_SERVICE_DAY_OFF_PATH])
        queryClient.invalidateQueries([SERVICE_DAY_OFF_PATH])
      },
    },
  )
}

export const useDeleteKioskDayOffMTT = () => {
  const queryClient = useQueryClient()
  return useMutation<unknown, unknown, number>(
    async (id) => {
      const res = await encoClient.delete<ResponseType<IGetKioskDayOffResponse>>(`${SERVICE_DAY_OFF_PATH}`, {
        params: { id },
      })
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SEARCH_SERVICE_DAY_OFF_PATH])
      },
    },
  )
}
