/* eslint-disable no-template-curly-in-string */
import { Button, Card, CheckboxOptionType, Col, Form, notification, Radio, Row, Space } from 'antd'
import { FormItemProps, RuleObject, RuleRender } from 'antd/lib/form'
import { styled } from 'goober'
import { useCallback, useMemo } from 'react'

import { UploadBannerMobileFormItem } from '_backend/components/upload/UploadBannerMobileFormItem'
import { UploadImageFormItem } from '_backend/components/upload/UploadImageFormItem'
import { Input } from 'components/Input'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { Txt } from 'components/Txt'
import { notifyFormValidateFailed } from 'helpers/form-helper'
import { ServiceActiveStatusEnum, ServiceShowOrHideEnum } from 'services/enco-service/enco-service-param'

const normalizeNumberOnly: FormItemProps['normalize'] = (value?: string) => {
  // eslint-disable-next-line no-useless-escape
  const tempValue = (value || '').replace(/[^0-9\.]+/g, '')
  if (!tempValue) {
    return tempValue
  } else {
    return Number(tempValue)
  }
}

const getValueIsValidWithRelateValue = (relateFieldValue: unknown, value: unknown) => {
  let isValid = false

  if (relateFieldValue === 0) isValid = true
  if (relateFieldValue) {
    if (typeof value === 'string') {
      if (value?.trim()) isValid = true
    } else if (value) isValid = true
  }

  return isValid
}

const CustomRow = styled('div')`
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  input {
    max-width: 94px;
  }
`

export interface IKioskServiceFormValues {
  id: number
  active: ServiceActiveStatusEnum

  serviceName: string
  propUnitName: string

  icon?: File | string

  coverImgMobile?: File | string

  bookingFunction: ServiceShowOrHideEnum
  bookingBtnName?: string

  minDateBooking?: number
  maxBookingPerDay?: number
  maxBookingPerWeek?: number
}

const serviceStatusOptions = [
  {
    value: ServiceActiveStatusEnum.ACTIVE,
    label: 'Active',
  },
  {
    value: ServiceActiveStatusEnum.INACTIVE,
    label: 'Inactive',
  },
]

const showHideOptions: CheckboxOptionType[] = [
  {
    value: ServiceShowOrHideEnum.SHOW,
    label: 'Show',
  },
  {
    value: ServiceShowOrHideEnum.HIDE,
    label: 'Hide',
  },
]

interface IKioskServiceFormProps {
  initialValues?: Partial<IKioskServiceFormValues>
  onSubmit: (values: IKioskServiceFormValues) => Promise<void>
  onCancel: () => void
}
export const KioskServiceForm = (props: IKioskServiceFormProps) => {
  const { onSubmit, onCancel, initialValues: preInitialValues } = props
  const modalConfirm = useModalConfirm()

  const [form] = Form.useForm<IKioskServiceFormValues>()
  const bookingFunction = Form.useWatch('bookingFunction', form)

  const isEditMode = useMemo(() => {
    return !!preInitialValues?.id
  }, [preInitialValues?.id])

  const initialValues = useMemo(() => {
    const temp: Partial<IKioskServiceFormValues> = { ...preInitialValues }
    const {
      active: tempActive = 1,

      bookingFunction: tempBookingFunction = 0,
      minDateBooking: tempMinDateBooking = 1,
      maxBookingPerDay: tempMaxBookingPerDay = 1,
      maxBookingPerWeek: tempMaxBookingPerWeek = 1,
    } = temp
    temp.active = tempActive
    temp.bookingFunction = tempBookingFunction
    temp.minDateBooking = tempMinDateBooking
    temp.maxBookingPerDay = tempMaxBookingPerDay
    temp.maxBookingPerWeek = tempMaxBookingPerWeek
    return temp
  }, [preInitialValues])

  const onConfirmSaveClick = useCallback(async () => {
    const values = form.getFieldsValue()
    await onSubmit(values)
    notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลสำเร็จ', duration: 2 })
  }, [form, onSubmit])

  const handleSubmit = useCallback(
    (values: IKioskServiceFormValues) => {
      let title = 'ยืนยันการเพิ่มบริการ EnCo Service'
      let content = 'คุณยืนยันที่จะเพิ่มบริการ EnCo Service หรือไม่?'
      if (isEditMode) {
        title = `ยืนยันการแก้ไขบริการ EnCo Service`
        content = `คุณยืนยันที่จะแก้ไขบริการ EnCo Service ${preInitialValues?.serviceName} นี้หรือไม่?`
      }
      modalConfirm.show({
        type: 'default',
        title,
        content,
        onOk: onConfirmSaveClick,
      })
    },
    [preInitialValues?.serviceName, isEditMode, modalConfirm, onConfirmSaveClick],
  )

  const validateRequireRelateCheckbox = useCallback((relateFieldName: string, errorMessage: string): RuleRender => {
    return ({ getFieldValue }) => {
      const validator = (_: RuleObject, value: string) => {
        const relateFieldValue = getFieldValue(relateFieldName)
        let isValid = getValueIsValidWithRelateValue(relateFieldValue, value)
        if (isValid) {
          return Promise.resolve()
        }
        return Promise.reject(new Error(errorMessage))
      }
      return {
        validator,
      }
    }
  }, [])

  return (
    <Form
      layout="vertical"
      autoComplete="off"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      onFinishFailed={notifyFormValidateFailed}
      scrollToFirstError
    >
      <Row gutter={[32, 32]}>
        {/* service detail */}
        <Col span={24}>
          <Card>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Txt ag="header30">การตั้งค่าบริการ EnCo Service</Txt>
              </Col>

              <Col span={24}>
                <Row>
                  <Col sm={12} xs={24}>
                    <Form.Item label="สถานะบริการ (Status)" name="active" required>
                      <Radio.Group options={serviceStatusOptions} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle" gutter={[24, 16]}>
                  <Col span={24}>
                    <Txt ag="header30">รายละเอียดบริการ</Txt>
                  </Col>
                  <Col sm={12} xs={24}>
                    <Form.Item
                      label="ชื่อบริการ (Service Name)"
                      name="serviceName"
                      rules={[
                        {
                          type: 'string',
                          required: true,
                          message: 'โปรดกรอกข้อมูล',
                        },
                      ]}
                    >
                      <Input placeholder="กรอกข้อมูล" maxLength={255} />
                    </Form.Item>
                  </Col>
                  <Col sm={12} xs={0} />
                  <Col sm={12} xs={24}>
                    <Form.Item
                      label="ชื่อ Property"
                      name="propUnitName"
                      rules={[
                        {
                          type: 'string',
                          required: true,
                          message: 'โปรดกรอกชื่อ Property',
                        },
                        {
                          max: 50,
                          message: 'ไม่เกิน 50 ตัวอักษร',
                        },
                      ]}
                      help="ตัวอย่าง สนาม, ห้องซ้อม, ห้องประชุม, สถานที่"
                    >
                      <Input placeholder="กรอกชื่อ Property" maxLength={50} />
                    </Form.Item>
                  </Col>
                  <Col sm={12} xs={24}></Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row align="middle" gutter={[24, 16]}>
                  <Col sm={12} xs={24}>
                    <Form.Item>
                      <UploadImageFormItem
                        form={form}
                        fieldName="icon"
                        label={<Txt ag="header30">ภาพไอคอน</Txt>}
                        title="ขนาดที่แนะนำ 150 x 150 หรืออัตราส่วน 1:1"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <Form.Item>
                  <UploadBannerMobileFormItem form={form} fieldName="coverImgMobile" />
                </Form.Item>
              </Col>

              {/* Booking */}
              <Col xs={24}>
                <Row align="middle" gutter={[24, 16]}>
                  <Col span={24}>
                    <Txt ag="header30">การ Booking (Booking)</Txt>
                  </Col>
                  <Col sm={16} xs={24}>
                    <Row>
                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="การแสดงผลฟีเจอร์การจอง"
                          name="bookingFunction"
                          rules={[
                            {
                              type: 'number',
                              required: true,
                              message: '',
                            },
                          ]}
                        >
                          <Radio.Group options={showHideOptions} />
                        </Form.Item>
                      </Col>
                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="ชื่อปุ่ม (Button Name)"
                          name="bookingBtnName"
                          rules={[validateRequireRelateCheckbox('bookingFunction', 'โปรดกรอกชื่อปุ่ม')]}
                          dependencies={['bookingFunction']}
                          required={!!bookingFunction}
                          requiredMark={!!bookingFunction}
                        >
                          <Input placeholder="กรอกชื่อปุ่ม" maxLength={255} disabled={!bookingFunction} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[8, 8]}>
                      <Col span={24}>
                        <Txt ag="header24">เงื่อนไขการจอง</Txt>
                      </Col>
                      <Col xs={24}>
                        <Row gutter={[8, 8]} align="middle">
                          <Col>
                            <Row gutter={[8, 8]} align="middle">
                              <Col>
                                <Txt ag="body20">ผู้ใช้หนึ่งราย ภายในหนึ่งสัปดาห์จองได้สูงสุดไม่เกิน</Txt>
                              </Col>
                              <Col>
                                <Form.Item
                                  name="maxBookingPerWeek"
                                  normalize={normalizeNumberOnly}
                                  rules={[validateRequireRelateCheckbox('bookingFunction', '')]}
                                  dependencies={['bookingFunction']}
                                  noStyle
                                >
                                  <Input min={1} max={30} disabled={!bookingFunction} />
                                </Form.Item>
                              </Col>
                              <Col>
                                <Txt ag="body20">วัน</Txt>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Row gutter={[8, 8]} align="middle">
                              <Col>
                                <Txt ag="body20">สูงสุด</Txt>
                              </Col>
                              <Col>
                                <Form.Item
                                  name="maxBookingPerDay"
                                  normalize={normalizeNumberOnly}
                                  rules={[validateRequireRelateCheckbox('bookingFunction', '')]}
                                  dependencies={['bookingFunction']}
                                  noStyle
                                >
                                  <Input min={1} max={30} disabled={!bookingFunction} />
                                </Form.Item>
                              </Col>
                              <Col>
                                <Txt ag="body20">ล็อก/วัน และต้องเป็นล็อคติดกันเท่านั้น</Txt>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Card style={{ marginTop: 8 }}>
        <Row>
          <Col span={12} className="text-left">
            <Button onClick={onCancel} htmlType="reset">
              กลับ
            </Button>
          </Col>
          <Col span={12} className="text-right">
            <Space>
              <Button type="primary" htmlType="submit">
                บันทึก
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>
    </Form>
  )
}
