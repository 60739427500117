import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row, Table, Tag } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { styled } from 'goober'
import { chain, orderBy } from 'lodash'
import { useCallback, useMemo, useState } from 'react'

import { SearchInput } from '_backend/components/SearchInput'
import { Txt } from 'components/Txt'
import { getColumnNumberByPage } from 'helpers/column-helper'
import { useVisible } from 'helpers/modal-helper'
import theme from 'theme/goober'

import { IKioskMasterProductFormValues } from './KioskMasterProductForm'
import { IModalKioskMasterSubProductFormValues, ModalKioskMasterSubProduct } from './ModalKioskMasterSubProduct'

const EyeIcon = styled(FontAwesomeIcon)`
  min-width: 40px;
  color: ${theme.color.lightBluePrimary};
  cursor: pointer;
`
type IMdKioskSubProductDtos = IKioskMasterProductFormValues['mdKioskSubProductDtos'][number]

interface IKioskMasterSubProductProps {
  value?: IMdKioskSubProductDtos[]
  onChange?: (values: IMdKioskSubProductDtos[]) => void
  disabled?: boolean
}

export const KioskMasterSubProduct = (props: IKioskMasterSubProductProps) => {
  const { onChange, disabled } = props

  const data = useMemo(() => props.value ?? [], [props.value])

  const [txtSearch, setTxtSearch] = useState<string>()
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(5)

  const modalKioskMasterSubProductVisible = useVisible()

  const [modalKioskPeriodFormValues, setModalKioskPeriodFormValues] = useState<
    Partial<IModalKioskMasterSubProductFormValues>
  >({})

  const dataSource = useMemo(() => {
    if (txtSearch) {
      return chain(data)
        .filter((d) => d.subProductName.includes(txtSearch))
        .orderBy(['subProductName'], ['asc'])
        .value()
    }
    return orderBy(data ?? [], ['subProductName'], ['asc'])
  }, [data, txtSearch])

  const onSearchService = useCallback((value?: string) => {
    setTxtSearch(value)
  }, [])

  const handlePageChange = useCallback((page: number, pageSize: number) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }, [])

  const renderTagActive = useCallback((_isActive: boolean, record: IMdKioskSubProductDtos) => {
    if (record.canceldate) {
      return <Tag color="error">Cancelled</Tag>
    }

    if (record.isActive) {
      return <Tag color="success">Active</Tag>
    }
    return <Tag color="default">Inactive</Tag>
  }, [])

  const onKioskMasterSubProductEditClick = useCallback(
    (d: IMdKioskSubProductDtos, recordIndex: number) => {
      d.id ??= 0
      setModalKioskPeriodFormValues({
        ...d,
        recordIndex,
      })
      modalKioskMasterSubProductVisible.open()
    },
    [modalKioskMasterSubProductVisible],
  )

  const onModalKioskMasterSubProductDismissClick = useCallback(() => {
    setModalKioskPeriodFormValues({})
    modalKioskMasterSubProductVisible.close()
  }, [modalKioskMasterSubProductVisible])

  const onModalKioskMasterSubProductConfirmClick = useCallback(
    (d: IModalKioskMasterSubProductFormValues) => {
      const { recordIndex, isEditMode } = d
      const newData = [...data]
      if (isEditMode) {
        newData[recordIndex] = d
      } else {
        newData.push(d)
      }
      onChange?.(newData)

      setModalKioskPeriodFormValues({})
      modalKioskMasterSubProductVisible.close()
    },
    [data, modalKioskMasterSubProductVisible, onChange],
  )

  const onModalKioskMasterSubProductDeleteClick = useCallback(
    (recordIndex: number) => {
      const newData = data.filter((e, index) => index !== recordIndex)
      onChange?.(newData)
      setModalKioskPeriodFormValues({})
      modalKioskMasterSubProductVisible.close()
    },
    [data, modalKioskMasterSubProductVisible, onChange],
  )

  const columns = useMemo(
    (): ColumnType<IMdKioskSubProductDtos>[] => [
      {
        title: 'ลำดับ',
        width: '50px',
        align: 'center',
        render: (_: any, record, index: number) => (
          <Txt ag="body20">{getColumnNumberByPage(currentPage, index, currentPageSize)}</Txt>
        ),
      },
      {
        title: 'ประเภทสินค้าย่อย',
        dataIndex: 'subProductName',
      },
      {
        title: 'สถานะ',
        dataIndex: 'isActive',
        render: renderTagActive,
      },
      {
        title: 'Modify Date',
        dataIndex: 'modifydateDisplay',
      },
      {
        title: 'Modify By',
        dataIndex: 'modifystfDisplay',
      },
      {
        dataIndex: 'action',
        width: '80px',
        render: (_: any, record, recordIndex) =>
          disabled ? (
            <></>
          ) : (
            <EyeIcon icon={faEdit} onClick={onKioskMasterSubProductEditClick.bind(null, record, recordIndex)} />
          ),
      },
    ],
    [renderTagActive, currentPage, currentPageSize, disabled, onKioskMasterSubProductEditClick],
  )

  return (
    <>
      <Row justify="space-between">
        <Col>
          <Txt ag="header24">ประเภทสินค้าย่อย</Txt>
        </Col>
        <Col>
          <Row
            gutter={[
              {
                sm: 24,
                xs: 16,
              },
              {
                sm: 16,
                xs: 16,
              },
            ]}
            justify="end"
            align="middle"
          >
            <Col>
              <SearchInput placeholder="ค้นหา" onSearch={onSearchService} />
            </Col>
            <Col>
              <Button type="primary" onClick={modalKioskMasterSubProductVisible.open} disabled={disabled}>
                เพิ่ม
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        scroll={{ x: '100%' }}
        dataSource={dataSource}
        columns={columns}
        rowKey={(e) => `${e.id || ''}-${e.subProductName}`}
        pagination={{
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          onChange: handlePageChange,
        }}
      />
      <ModalKioskMasterSubProduct
        visible={modalKioskMasterSubProductVisible.visible}
        initialValues={modalKioskPeriodFormValues}
        onDismiss={onModalKioskMasterSubProductDismissClick}
        onConfirm={onModalKioskMasterSubProductConfirmClick}
        onDelete={onModalKioskMasterSubProductDeleteClick}
      />
    </>
  )
}
