import { Button, Col, DatePicker, Form, Row, Select } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import dayjs from 'dayjs'
import { chain } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { componentDisplayDateFormat } from '_backend/constants/date-format'
import { REQUIRED_RULES } from '_backend/constants/form-rules'
import { AppModal } from 'components/AppModal'
import RangePicker from 'components/RangePicker'
import { backendPaths } from 'paths/backend-path-config'
import { GetServiceTypeEnum, ServiceActiveStatusEnum } from 'services/enco-service/enco-service-param'
import { useGetServicesQRY } from 'services/enco-service/enco-service-service'
import { IGetReportParams } from 'services/report/report-params'
import { IGetReportListResponse } from 'services/report/report-response'

export interface IReportFilterConfig {
  filterDate: boolean
  filterDateRange: boolean
  filterMonthYear: boolean
  filterService: boolean
  filterInvoiceType: boolean
  filterPackageType: boolean

  filterServiceKioskOnly: boolean
}

export type IPageReportQueryParams = Omit<IGetReportParams, 'reportId'>

export enum EnumReportId {
  'รายงานการรับชำระเงิน' = 1,
  'รายงานวันที่ลูกค้าเข้ารับบริการ' = 2,
  'รายงานสรุปวงเงินคงเหลือใน wallet' = 3,
  'รายงาน Kiosk Daily Checklist' = 4,
  'รายงาน สรุปยอดจอง EnCo Kiosk' = 5,
  'รายงานสรุปการชำระเงิน EnCo Kiosk' = 6,
  'รายงานสรุปจองแบบ Sponsor สำหรับ EnCo Stadium' = 7,
  'รายงานสรุปการชำระเงิน สำหรับ EnCo Stadium' = 8,
  'รายงานข้อมูลสำหรับใช้ออกใบเสร็จ สำหรับ EnCo Stadium' = 9,
}

export const getReportConfig = (reportId: number) => {
  const config: IReportFilterConfig = {
    filterDate: false,
    filterDateRange: false,
    filterMonthYear: false,
    filterService: false,
    filterInvoiceType: false,
    filterPackageType: false,
    filterServiceKioskOnly: false,
  }

  switch (reportId) {
    case EnumReportId['รายงานข้อมูลสำหรับใช้ออกใบเสร็จ สำหรับ EnCo Stadium']:
    case EnumReportId['รายงานสรุปการชำระเงิน สำหรับ EnCo Stadium']:
      config.filterService = true
      config.filterMonthYear = true
      break
    case EnumReportId['รายงาน สรุปยอดจอง EnCo Kiosk']:
      config.filterService = true
      config.filterServiceKioskOnly = true
      config.filterMonthYear = true
      break
    case EnumReportId['รายงานสรุปจองแบบ Sponsor สำหรับ EnCo Stadium']:
      config.filterPackageType = true
      config.filterDateRange = true
      break
    case EnumReportId['รายงานสรุปการชำระเงิน EnCo Kiosk']:
      config.filterService = true
      config.filterServiceKioskOnly = true
      config.filterInvoiceType = true
      config.filterMonthYear = true
      break

    case EnumReportId['รายงาน Kiosk Daily Checklist']:
      config.filterService = true
      config.filterServiceKioskOnly = true
      config.filterDate = true
      break
    default:
      config.filterService = true
      config.filterDateRange = true
      break
  }

  return config
}

interface ReportFilterModalProps {
  report: IGetReportListResponse
  visible: boolean
  onCloseModal: () => void
}

export interface ReportFilterFormValue {
  serviceId?: number
  rangeDate?: [dayjs.Dayjs, dayjs.Dayjs]
  startDate?: dayjs.Dayjs
  monthYear?: dayjs.Dayjs
  invoiceTypeId?: number
  packageTypeId?: number
}

export const getFilterValue = (index: number, values?: [dayjs.Dayjs, dayjs.Dayjs]) => {
  if (!values) return ''
  if (values.length < 1) return ''
  const value = values[index]
  if (value) return value.format(componentDisplayDateFormat)
  return ''
}

export const ReportFilterModal = (props: ReportFilterModalProps) => {
  const { visible, onCloseModal, report } = props
  const { data: services } = useGetServicesQRY({ serviceType: GetServiceTypeEnum.ALL })

  const navigate = useNavigate()

  const [form] = Form.useForm()

  const filterConfig = useMemo(() => {
    return getReportConfig(report.id)
  }, [report.id])

  const handleCloseModal = useCallback(() => {
    onCloseModal()
    form.resetFields()
  }, [onCloseModal, form])

  const handleFinishForm = useCallback(
    (values: ReportFilterFormValue) => {
      const reportParams: IPageReportQueryParams = {}

      if (values.serviceId) {
        reportParams.serviceId = values.serviceId
      }

      if (values.rangeDate) {
        reportParams.startDate = getFilterValue(0, values.rangeDate)
        reportParams.endDate = getFilterValue(1, values.rangeDate)
      }

      if (values.startDate) {
        reportParams.startDate = dayjs(values.startDate).format(componentDisplayDateFormat)
      }

      if (values.monthYear) {
        const e = dayjs(values.monthYear)
        reportParams.year = e.year()
        reportParams.month = e.month() + 1 // +1 because dayjs month start at 0
      }

      if (values.invoiceTypeId) {
        reportParams.invoiceTypeId = values.invoiceTypeId
      }

      if (values.packageTypeId) {
        reportParams.packageTypeId = values.packageTypeId
      }

      navigate(
        backendPaths.report({
          routeParam: { reportId: report?.id },
          queryParam: reportParams,
        }),
      )

      handleCloseModal()
    },
    [handleCloseModal, navigate, report?.id],
  )

  const serviceOptions = useMemo((): DefaultOptionType[] => {
    return chain(services ?? [])
      .filter((e) => {
        const isKiosk = +e.mdServiceCatId === 3
        const isActive = e.active === ServiceActiveStatusEnum.ACTIVE

        if (filterConfig.filterServiceKioskOnly) {
          return isKiosk && isActive
        }
        return !isKiosk && isActive
      })
      .orderBy([(e) => e.recno], ['asc'])
      .map((e): DefaultOptionType => {
        return {
          label: e.name,
          value: e.id,
        }
      })
      .value()
  }, [filterConfig.filterServiceKioskOnly, services])

  // TODO: options เอามาจากไหนหว่า
  const invoiceTypeOptions = useMemo((): DefaultOptionType[] => {
    return chain([
      {
        name: 'เต็มรูปแบบ',
        id: 1,
      },
    ])
      .map((e): DefaultOptionType => {
        return {
          label: e.name,
          value: e.id,
        }
      })
      .value()
  }, [])

  const packageOptions = useMemo((): DefaultOptionType[] => {
    return chain([
      {
        name: 'Gold Package',
        id: 1,
      },
    ])
      .map((e): DefaultOptionType => {
        return {
          label: e.name,
          value: e.id,
        }
      })
      .value()
  }, [])

  return (
    <AppModal visible={visible}>
      <AppModal.Header onCloseClick={handleCloseModal}>{report.reportName}</AppModal.Header>
      <Form labelCol={{ span: 24 }} form={form} onFinish={handleFinishForm}>
        <AppModal.Body>
          <Row gutter={24}>
            {filterConfig.filterService && (
              <Col sm={24}>
                <Form.Item name="serviceId" label="ชื่อบริการ" rules={REQUIRED_RULES} required>
                  <Select placeholder="เลือกบริการ" options={serviceOptions} />
                </Form.Item>
              </Col>
            )}
            {filterConfig.filterInvoiceType && (
              <Col sm={24}>
                <Form.Item name="invoiceTypeId" label="ประเภทใบเสร็จ" rules={REQUIRED_RULES} required>
                  <Select placeholder="เลือกประเภทใบเสร็จ" options={invoiceTypeOptions} />
                </Form.Item>
              </Col>
            )}
            {filterConfig.filterPackageType && (
              <Col sm={24}>
                <Form.Item name="packageTypeId" label="Package" rules={REQUIRED_RULES} required>
                  <Select placeholder="เลือก Package" options={packageOptions} />
                </Form.Item>
              </Col>
            )}
            {filterConfig.filterDate && (
              <Col sm={24}>
                <Form.Item name="startDate" label="วันที่ต้องการตรวจสอบการบริการ" rules={REQUIRED_RULES} required>
                  <DatePicker />
                </Form.Item>
              </Col>
            )}
            {filterConfig.filterMonthYear && (
              <Col sm={24}>
                <Form.Item name="monthYear" label="เดือน-ปี" rules={REQUIRED_RULES} required>
                  <DatePicker.MonthPicker />
                </Form.Item>
              </Col>
            )}
            {filterConfig.filterDateRange && (
              <Col sm={24}>
                <Form.Item name="rangeDate" label="ช่วงวันที่ที่ต้องการเรียกดูข้อมูล" rules={REQUIRED_RULES} required>
                  <RangePicker />
                </Form.Item>
              </Col>
            )}
          </Row>
        </AppModal.Body>
        <AppModal.Footer>
          <Button onClick={handleCloseModal}>ยกเลิก</Button>

          <Button htmlType="submit">แสดงข้อมูล</Button>
        </AppModal.Footer>
      </Form>
    </AppModal>
  )
}
