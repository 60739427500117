import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Col, Form, notification, Radio, Row, Select, Space, Switch } from 'antd'
import { RuleRender } from 'antd/lib/form'
import { NamePath } from 'antd/lib/form/interface'
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select'
import { styled } from 'goober'
import { chain, flatten, map } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { requiredCustomMessage } from '_backend/constants/form-rules'
import { Input } from 'components/Input'
import { Txt } from 'components/Txt'
import { backendPaths } from 'paths/backend-path-config'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import {
  useGetMasterSlotQRY,
  useSaveServicePropMTT,
  useSaveServicePropRelateMTT,
  useSaveServicePropSlotMTT,
} from 'services/property-management/property-management-service'
import { IServicePropParams } from 'services/property-management/property-management.param'
import { useGetServicePropListQRY } from 'services/service-property/service-property-service'
import theme from 'theme/goober'

const Icon = styled(FontAwesomeIcon)`
  color: ${theme.color.lightBluePrimary};
  cursor: pointer;
`

interface IPanelDayProps {
  day: IDay
  isActive: boolean

  nameSwitch: string
}
export enum IDay {
  sun = 'วันอาทิตย์',
  mon = 'วันจันทร์',
  tue = 'วันอังคาร',
  wed = 'วันพุธ',
  thu = 'วันพฤหัส',
  fri = 'วันศุกร์',
  sat = 'วันเสาร์',
}

const statusPropertyOptions = [
  { label: 'Active', value: 1 },
  { label: 'In Active', value: 0 },
]

export const PanelDay = (props: IPanelDayProps) => {
  const { day, isActive, nameSwitch } = props
  const [input, setInput] = useState(isActive)
  const { data: masterSlotList } = useGetMasterSlotQRY()

  const userSlotTimeOptionsStart = useMemo(
    () =>
      map(
        masterSlotList,
        (e): DefaultOptionType => ({
          value: e.id,
          label: e.start_t,
        }),
      ),
    [masterSlotList],
  )

  const userSlotTimeOptionsEnd = useMemo(
    () =>
      map(
        masterSlotList,
        (e): DefaultOptionType => ({
          value: e.id,
          label: e.end_t,
        }),
      ),
    [masterSlotList],
  )
  const onClickSwitchTwo = useCallback(() => {
    setInput(!input)
  }, [input])

  const optionsIntervalType = [
    {
      value: 'Normal',
      label: 'Normal Time',
    },
    {
      value: 'Peak',
      label: 'Peak Time',
    },
  ]

  const validateRequireRelateMoreThanCheckbox = useCallback(
    (relateFieldName: NamePath, errorMessage: string): RuleRender =>
      ({ getFieldValue }) => ({
        validator(_, value: string) {
          const relateFieldValue = getFieldValue(relateFieldName)

          let isValid
          if (relateFieldValue === undefined) {
            isValid = true
          }
          if (relateFieldValue <= value) {
            isValid = true
          }

          if (isValid) {
            return Promise.resolve()
          }
          return Promise.reject(new Error(errorMessage))
        },
      }),
    [],
  )

  return (
    <Card style={{ width: '100%', marginTop: 8 }}>
      <Row gutter={[16, 16]}>
        <Col span={3}>
          <Form.Item name={nameSwitch}>
            <Switch checked={input} checkedChildren="On" unCheckedChildren="Off" onChange={onClickSwitchTwo} />
          </Form.Item>

          <Txt ag="header24" style={{ marginLeft: 8, textAlign: 'center' }}>
            {day}
          </Txt>
        </Col>
        <Col span={20}>
          <Form.List name={day}>
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <div key={field.key}>
                      <Card style={{ backgroundColor: '#E6F3FC', width: '100%', marginBottom: '8px' }}>
                        <Row gutter={[16, 16]}>
                          <Col span={6}>
                            <Txt ag="body20">ช่วงเวลาเปิดทำการ</Txt>
                          </Col>
                          <Col span={6} />
                          <Col span={10}>
                            <Txt ag="body20">ประเภทช่วงเวลา</Txt>
                          </Col>

                          <Col span={6}>
                            <Form.Item
                              validateTrigger={['onChange', 'onBlur']}
                              shouldUpdate
                              name={[index, 'mdSlotIdStart']}
                              required
                              rules={[
                                validateRequireRelateMoreThanCheckbox(
                                  [day, index - 1, 'mdSlotIdEnd'],
                                  'กรุณาเลือกเวลาที่มากกว่าด้านบน',
                                ),
                              ]}
                            >
                              <Select style={{ width: '100%', height: 38 }} options={userSlotTimeOptionsStart} />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              validateTrigger={['onChange', 'onBlur']}
                              name={[index, 'mdSlotIdEnd']}
                              required
                              rules={[
                                validateRequireRelateMoreThanCheckbox(
                                  [day, index, 'mdSlotIdStart'],
                                  'กรุณาเลือกเวลาที่มากกว่า',
                                ),
                              ]}
                            >
                              <Select style={{ width: '100%', height: 38 }} options={userSlotTimeOptionsEnd} />
                            </Form.Item>
                          </Col>
                          <Col span={10}>
                            <Form.Item
                              validateTrigger={['onChange', 'onBlur']}
                              name={[index, 'slotType']}
                              required
                              rules={requiredCustomMessage('กรุณาเลือกประเภทเวลา')}
                            >
                              <Select style={{ width: '100%', height: 38 }} options={optionsIntervalType} />
                            </Form.Item>
                          </Col>
                          <Col style={{ alignSelf: 'center', marginTop: '-21px' }}>
                            <Icon icon={faTrashCan} onClick={() => remove(field.name)} />
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  ))}

                  <Form.Item>
                    <Button style={{ width: '100%', marginTop: '8px' }} onClick={() => add()} disabled={!input}>
                      เพิ่มเวลาเปิดทำการ
                    </Button>
                  </Form.Item>
                </div>
              )
            }}
          </Form.List>
        </Col>
      </Row>
    </Card>
  )
}
interface IPropertySlotFormProps {
  initialValuesSlot: any
  initialValuesServiceProp: any
  isEdit?: boolean
}
export interface ISlot {
  mdSlotIdEnd: number
  mdSlotIdStart: number
  slotType: string
}
interface IValueForm {
  name: string
  sun: boolean
  mon: boolean
  tue: boolean
  wed: boolean
  thu: boolean
  fri: boolean
  sat: boolean
  active: 1 | 0
  วันจันทร์: ISlot[]
  วันพฤหัส: ISlot[]
  วันพุธ: ISlot[]
  วันศุกร์: ISlot[]
  วันอังคาร: ISlot[]
  วันอาทิตย์: ISlot[]
  วันเสาร์: ISlot[]

  servicePropRelates: number[]
}

export const PropertySlotForm = (props: IPropertySlotFormProps) => {
  const { initialValuesSlot, initialValuesServiceProp, isEdit } = props

  const navigate = useNavigate()
  const query = useParams()
  const serviceId = Number(query.serviceId || 0)
  const propertyId = Number(query.propertyId || 0)
  const { data: service } = useGetServiceQRY(Number(serviceId))
  const { mdServiceCatId = 0 } = service ?? {}
  const isKiosk = +mdServiceCatId === 3

  const { data: serviceProperties } = useGetServicePropListQRY({
    ServiceId: serviceId,
  })

  const { mutate: mutateSaveServicePropRelate } = useSaveServicePropRelateMTT()

  const [form] = Form.useForm<IValueForm>()

  useEffect(() => {
    form.setFieldsValue(initialValuesSlot)
  }, [form, initialValuesSlot, initialValuesServiceProp])

  const { mutateAsync: mutateSaveServiceProp } = useSaveServicePropMTT()
  const { mutate: mutateSaveServicePropSlot } = useSaveServicePropSlotMTT()

  const prePareSlot = useCallback((slot: ISlot[], day: string) => {
    return slot.map((item) => {
      return {
        mdSlotIdStart: item.mdSlotIdStart,
        mdSlotIdEnd: item.mdSlotIdEnd,
        slotType: item.slotType,
        day: day,
        id: 0,
      }
    })
  }, [])

  const handleFinish = useCallback(
    async (values: IValueForm) => {
      values.servicePropRelates ??= []

      const paramsSubmit = [
        values.วันอาทิตย์ && prePareSlot(values.วันอาทิตย์, 'sun'),
        values.วันจันทร์ && prePareSlot(values.วันจันทร์, 'mon'),
        values.วันอังคาร && prePareSlot(values.วันอังคาร, 'tue'),
        values.วันพุธ && prePareSlot(values.วันพุธ, 'wed'),
        values.วันพฤหัส && prePareSlot(values.วันพฤหัส, 'thu'),
        values.วันศุกร์ && prePareSlot(values.วันศุกร์, 'fri'),
        values.วันเสาร์ && prePareSlot(values.วันเสาร์, 'sat'),
      ]

      const flatItem = flatten(paramsSubmit)

      const valuesForm = form.getFieldsValue()
      const paramsSaveServiceProps: IServicePropParams = {
        id: propertyId || 0,
        serviceId: serviceId,
        name: valuesForm.name,
        sun: values.sun ? 1 : 0,
        mon: values.mon ? 1 : 0,
        tue: values.tue ? 1 : 0,
        wed: values.wed ? 1 : 0,
        thu: values.thu ? 1 : 0,
        fri: values.fri ? 1 : 0,
        sat: values.sat ? 1 : 0,
        active: valuesForm.active,
      }
      const response = await mutateSaveServiceProp(paramsSaveServiceProps)

      const servicePropSlotParam = flatItem.filter((item) => item !== undefined)

      if (response?.id) {
        if (!isKiosk) {
          mutateSaveServicePropSlot({ servicePropSlot: servicePropSlotParam, servicePropId: response.id })
        }

        if (isKiosk) {
          mutateSaveServicePropRelate({
            TbServicePropId: response.id,
            TbServicePropRelates: values.servicePropRelates.map((e) => ({
              relatePropId: e,
            })),
          })
        }
      }

      form.resetFields()
      navigate(backendPaths.serviceEncoPropertyManagementList({ routeParam: { serviceId: Number(serviceId) } }))
      notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลสำเร็จ', duration: 2 })
    },
    [
      form,
      isKiosk,
      mutateSaveServiceProp,
      mutateSaveServicePropSlot,
      navigate,
      prePareSlot,
      propertyId,
      mutateSaveServicePropRelate,
      serviceId,
    ],
  )

  const propertyOptions = useMemo(() => {
    return chain(serviceProperties ?? [])
      .filter((e) => e.id !== propertyId)
      .map(
        (e): BaseOptionType => ({
          value: e.id,
          label: e.name,
        }),
      )
      .orderBy(['label', 'asc'])
      .value()
  }, [propertyId, serviceProperties])

  return (
    <>
      <ContentHeader title={service?.name} subTitle={isEdit ? 'Edit Property' : 'New Property'} />
      <ContentBody>
        <Card>
          <Txt ag="header30">รายละเอียด Property</Txt>
          <Form form={form} initialValues={initialValuesServiceProp} style={{ marginTop: 16 }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Txt ag="body20">สถานะ Property</Txt>
              <Form.Item name="active" required>
                <Radio.Group options={statusPropertyOptions} />
              </Form.Item>
            </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 10, paddingTop: 8 }}>
              <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                <Txt ag="body20">ชื่อบริการ (Service Name)</Txt>
                <Input disabled value={service?.name} />
              </div>
              <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                <Txt ag="body20">ชื่อ Property</Txt>
                <Form.Item name="name" required rules={requiredCustomMessage('กรุณากรอกชื่อ')}>
                  <Input />
                </Form.Item>
              </div>
            </div>
          </Form>
        </Card>
        <Form form={form} onFinish={handleFinish} initialValues={initialValuesSlot} layout="vertical">
          {isKiosk ? (
            <Card style={{ marginTop: 8 }}>
              <Space size={10} direction="vertical" style={{ width: '100%' }}>
                <DaySwitch label={IDay.sun} name="sun" />
                <DaySwitch label={IDay.mon} name="mon" />
                <DaySwitch label={IDay.tue} name="tue" />
                <DaySwitch label={IDay.wed} name="wed" />
                <DaySwitch label={IDay.thu} name="thu" />
                <DaySwitch label={IDay.fri} name="fri" />
                <DaySwitch label={IDay.sat} name="sat" />
                <Row style={{ width: '100%' }}>
                  <Col span={6}>
                    <Form.Item name="servicePropRelates" label="เลือกล็อคข้างเคียง" style={{ marginTop: 8 }}>
                      <Select mode="multiple" placeholder="เลือกล็อคข้างเคียง" options={propertyOptions} />
                    </Form.Item>
                  </Col>
                </Row>
              </Space>
            </Card>
          ) : (
            <>
              <PanelDay day={IDay.sun} isActive={initialValuesSlot.sun} nameSwitch="sun" />
              <PanelDay day={IDay.mon} isActive={initialValuesSlot.mon} nameSwitch="mon" />
              <PanelDay day={IDay.tue} isActive={initialValuesSlot.tue} nameSwitch="tue" />
              <PanelDay day={IDay.wed} isActive={initialValuesSlot.wed} nameSwitch="wed" />
              <PanelDay day={IDay.thu} isActive={initialValuesSlot.thu} nameSwitch="thu" />
              <PanelDay day={IDay.fri} isActive={initialValuesSlot.fri} nameSwitch="fri" />
              <PanelDay day={IDay.sat} isActive={initialValuesSlot.sat} nameSwitch="sat" />
            </>
          )}

          <Card style={{ marginTop: 8 }}>
            <Row>
              <Col span={12} className="text-left">
                <Link
                  to={'..'}
                  onClick={(e) => {
                    e.preventDefault()
                    navigate(-1)
                  }}
                >
                  <Button>กลับ</Button>
                </Link>
              </Col>
              <Col span={12} className="text-right">
                <Button htmlType="submit" type="primary">
                  บันทึก
                </Button>
              </Col>
            </Row>
          </Card>
        </Form>
      </ContentBody>
    </>
  )
}

interface IDaySwitchProps {
  name: string
  label: string
  disabled?: boolean
}
const DaySwitch = (props: IDaySwitchProps) => {
  const { name, label, disabled } = props
  return (
    <Row gutter={10} align="middle" wrap={false}>
      <Col>
        <Form.Item name={name} noStyle valuePropName="checked">
          <Switch checkedChildren="On" unCheckedChildren="Off" disabled={disabled} />
        </Form.Item>
      </Col>
      <Col>
        <Txt ag="body18">{label}</Txt>
      </Col>
    </Row>
  )
}
