import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tag } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { styled } from 'goober'
import { useCallback, useMemo } from 'react'

import { SortableTable } from '_backend/components/SortableTable'
import { GetServiceTypeEnum } from 'services/enco-service/enco-service-param'
import { IServiceData } from 'services/enco-service/enco-service-response'
import { useGetServicesQRY, useMutationSetServiceDisplayRecNoMTT } from 'services/enco-service/enco-service-service'
import theme from 'theme/goober'

export const GearIcon = styled(FontAwesomeIcon)`
  color: ${theme.color.lightBluePrimary};
  cursor: pointer;
`

export const GearIconDisabled = styled(FontAwesomeIcon)`
  color: ${theme.color.darkGray};
  cursor: not-allowed;
`

interface ServiceDisplayTablePropsType {
  txtSearch?: string
  serviceType: GetServiceTypeEnum.ENCO_SERVICE | GetServiceTypeEnum.OTHER_SERVICE
}
export const ServiceDisplayTable = (props: ServiceDisplayTablePropsType) => {
  const { txtSearch, serviceType } = props

  const { mutate: setServiceDisplayRecNo } = useMutationSetServiceDisplayRecNoMTT()

  const { data: dataSource, isLoading } = useGetServicesQRY({
    serviceDisplayType: serviceType,
    txtSearch,
  })

  const columns = useMemo(
    (): ColumnType<IServiceData>[] => [
      {
        title: 'ลำดับ',
        dataIndex: 'displayRecNo',
        width: '50px',
        align: 'center',
      },
      {
        title: 'ชื่อบริการ',
        dataIndex: 'name',
      },
      {
        title: 'ประเภทบริการ',
        dataIndex: 'serviceType',
      },
      {
        title: 'สถานะ',
        dataIndex: 'active',
        render(value) {
          return <Tag color={value ? 'success' : 'default'}>{value ? 'Active' : 'Inactive'}</Tag>
        },
      },
      {
        title: 'Modify By',
        dataIndex: 'modifystfnm',
      },
      {
        title: 'Modify Date',
        dataIndex: 'modifydatenm',
      },
      {
        title: '',
        dataIndex: 'action',
        width: '16px',
        render: (value, record) => {
          return <div />
        },
      },
    ],
    [],
  )

  const handleSortEnd = useCallback(
    (sortedSeq: number, sortedData: IServiceData) => {
      setServiceDisplayRecNo({
        newRow: sortedSeq,
        id: sortedData.id,
      })
    },
    [setServiceDisplayRecNo],
  )

  return (
    <SortableTable
      dataSource={dataSource}
      columns={columns}
      loading={isLoading}
      rowKey="id"
      onSortEnd={handleSortEnd}
    />
  )
}
